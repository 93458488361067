import React, { useState, useEffect } from 'react';
import DropDown from '../components/UI/DropDownCustom';
import useApi from '../api/useApi';
import { GET_CLASSES, GET_INSTITUTES, DELETE_CLASSES } from '../api/urls';
import AddClass from '../components/ClassSection/AddClass';
import AddSection from '../components/ClassSection/AddSection';

function ClassSection() {
  const [institutes, setInstitutes] = useState([]);
  const [selectedInstitution, selectInstitution] = useState();
  const [classes, setClasses] = useState();
  const [addClass, setAddClass] = useState(false);
  const [addSection, setAddSection] = useState(false);

  const [instituteRes, getInstitutes] = useApi();
  const [classesRes, getClasses] = useApi();
  const [deleteClassRes, deleteClass] = useApi();

  useEffect(() => {
    getInstitutes(GET_INSTITUTES, 'GET');
  }, []);

  useEffect(() => {
    if (!instituteRes.isLoading && !instituteRes.isError && instituteRes.data) {
      setInstitutes(instituteRes.data.response);
    }
  }, [instituteRes]);

  useEffect(() => {
    if (selectedInstitution) {
      getClasses(GET_CLASSES(selectedInstitution), 'GET');
    }
  }, [selectedInstitution]);

  useEffect(() => {
    if (!classesRes.isLoading && !classesRes.isError && classesRes.data) {
      setClasses(classesRes.data);
    }
  }, [classesRes]);

  const handleCloseClass = () => {
    setAddClass(false);
  };

  const refreshList = () => {
    getClasses(GET_CLASSES(selectedInstitution), 'GET');
  };

  const handleDeleteClass = (_class) => {
    deleteClass(DELETE_CLASSES(_class._id), 'DELETE', { instituteId: selectedInstitution });
  };

  useEffect(() => {
    if (!deleteClassRes.isLoading && !deleteClassRes.isError && deleteClassRes.status == 201) {
      alert('Class Deleted successfully');
      refreshList();
    }

    if (!deleteClassRes.isLoading && deleteClassRes.isError) {
      alert('Something went wrong');
    }
  }, [deleteClassRes]);

  return (
    <>
      {addClass && (
        <AddClass onClose={handleCloseClass} refreshList={refreshList} selectedInstitution={selectedInstitution} />
      )}

      {addSection && (
        <AddSection
          onClose={() => setAddSection(null)}
          selectedInstitution={selectedInstitution}
          selectedClass={addSection}
        />
      )}

      <div className="w-4/5 pb-10">
        <div className="w-full bg-white rounded-xl h-auto mb-4">
          <div className="items-center px-6 py-3 border-b border-borderYellow">
            <div className="flex justify-between items-center">
              <div className="font-bold w-1/3">
                <p>Classes & Sections</p>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap w-full h-1/4 px-6">
            <div className="w-full">
              <DropDown
                width={'w-1/3'}
                title={'Select Institution'}
                data={institutes}
                field={'name'}
                type={'obj'}
                handleItemClick={(x) => {
                  selectInstitution(x?._id);
                }}
                margin={'mt-4 mx-auto'}
              />
            </div>
            {classesRes.isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 m-auto mt-10" alt="" />
            ) : classes ? (
              <div className="w-full py-4">
                <button
                  className="border py-2 ml-auto w-40 mb-4 rounded-3xl text-xs font-bold focus:outline-none flex justify-center items-center bg-primary text-white"
                  onClick={() => setAddClass(true)}
                >
                  Add Class
                </button>
                <div className="bg-white m-auto border rounded-2xl border-borderYellow">
                  <div className="w-full flex bg-tableHeader rounded-3xl">
                    <div className="flex w-full relative text-left px-4 text-xs font-medium">
                      <div className="w-1/2 border-r-2 py-2 px-2">Class Name</div>
                      <div className="w-1/2 py-2 px-2">Action</div>
                    </div>
                  </div>
                  <div className="showScroll" style={{ maxHeight: '60vh' }}>
                    {Array.isArray(classes) &&
                      classes.map((x, idx) => {
                        return (
                          <div className="w-full">
                            <div
                              className={`justify-center flex w-full relative text-left py-2 px-4 text-xs font-medium items-center ${
                                idx !== classes.length - 1 && 'border-b border-borderYellow'
                              }`}
                            >
                              <div className="w-1/2 px-2 text-textDark ">{x?.name}</div>

                              <div className={`w-1/2 px-2 flex`}>
                                {' '}
                                <button
                                  className=" border  py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center px-2 mr-3 border-primary text-primary"
                                  onClick={() => {
                                    setAddSection(x);
                                  }}
                                >
                                  View & Edit Sections
                                </button>
                                <button
                                  className=" border  py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center px-6 mr-3 border-primary text-primary"
                                  onClick={() => {
                                    handleDeleteClass(x);
                                  }}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            ) : (
              <p className="w-full text-center text-xl font-semibold my-10 text-primary">
                Select Institution To Get Classes
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ClassSection;

import React, { useEffect, useState } from 'react';
import useApi from '../api/useApi';
import { GET_University_Events } from '../api/urls';
import Add from '../components/UniversityEvents/Add';

const UniversityEvents = () => {
  const [open, setOpenAdd] = useState(null);
  const [eventList, setEventList] = useState();

  const [ loadEventsRes , getEvents ] = useApi();

  const handleClose = (refresh) => {
    if (refresh) getEventList();
    setOpenAdd(null);
  };
  const handleDelete = (deleteID) => {
    getEvents(GET_University_Events,'POST', { deleteID : deleteID });
  }

  useEffect(() => {
    getEventList();
  }, []);

  const getEventList = () => {
    getEvents(GET_University_Events, 'GET');
  };

  useEffect(() => {
    if (loadEventsRes.data?.dataList && Array.isArray(loadEventsRes.data?.dataList) ) 
      { setEventList(loadEventsRes.data?.dataList) ; }
    if( loadEventsRes.data?.IsDelete ){ setTimeout(() => { alert("University Event deleted sucessfully.") ; }, 400);  }
  }, [loadEventsRes]);

  return (
    <div className="w-4/5 pb-10">
      {open ? (
        <Add onClose={handleClose} />
      ) : (
        <div className="w-full bg-white rounded-xl h-auto mb-4 ">
          <div className="items-center px-6 py-3 mb-4 border-b border-borderYellow">
            <div className="flex justify-between items-center">
              <div className="font-bold w-1/3">
                <p>University Events</p>
              </div>
              <div className="flex gap-x-4 w-2/3 justify-end">
                <button
                  className="w-32 border px-12 py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 bg-primary border-primary text-white"
                  onClick={() => setOpenAdd(true)}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          { loadEventsRes.isLoading ? (
            <img src="assets/img/loader.svg" className="w-14 mx-auto my-10" alt="" />
          ) : (
            eventList && // Object.entries(holidayList)
            eventList.map((x) => (
              <div className="flex flex-wrap w-full px-6">
                <p className=" font-semibold">{x[0]}</p>
                <div className="w-full pt-2 pb-4">
                  <div className="bg-white m-auto border rounded-2xl border-borderYellow">
                    <div className="w-full flex bg-tableHeader rounded-3xl">
                      <div className="flex w-full relative text-left px-4 text-xs font-medium">
                        <div className="w-2/12 py-2 px-2 border-r-2">Type</div> 
                        <div className="w-2/12 py-2 px-2 border-r-2">Name</div> 
                        <div className="w-2/12 py-2 px-2 border-r-2">Start Date</div>
                        <div className="w-2/12 py-2 px-2 border-r-2">End Date</div>
                        <div className="w-3/12 py-2 px-2">Description</div>
                        <div className="w-1/12 py-2 px-2">Action</div>
                      </div>
                    </div>
                    <div className="customScrollBar" style={{ maxHeight: '45vh' }}>
                      {Array.isArray(x[1]) ? (
                        x[1].map((c, idx) => {
                          return ( //  <!-- justify-center -->
                            <div className="w-full">
                              <div
                                className={`flex w-full relative text-left py-4 px-4 text-xs font-medium items-center ${
                                  x[1].length - 1 !== idx && 'border-b border-borderYellow'
                                }`}
                              >
                                <div className="w-2/12 px-2 text-tableRowText ">{c?.type}</div>
                                <div className="w-2/12 px-2 text-tableRowText ">{c?.name}</div>
                                <div className="w-2/12 px-2 text-tableRowText ">{c?.startDate}</div>
                                <div className="w-2/12 px-2 text-tableRowText ">{c?.endDate}</div>
                                <div className="w-3/12 px-2 text-tableRowText ">{c?.desc}</div> 
                                <div className="w-1/12 px-2 text-tableRowText ">
                                  <button
                                    className="w-full border py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 bg-primary border-primary text-white"
                                    onClick={() => handleDelete(c?.eventID)}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p className="text-lg text-center my-10">No Record found</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default UniversityEvents ;

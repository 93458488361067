import React, { useEffect, useState } from 'react';
import DropDown from '../UI/DropDownCustom';

import { FlexWrapperTwo, VideoTag } from './FeedsStyle';
import { DELETE_FEED } from '../../api/urls';
import useApi from '../../api/useApi';
import ImageCarousel from './ImageCarousel';

function Feeds({ feed2, refreshData }) {
  const [feed, setFeed] = useState(feed2);

  const [deleteRes, deletePost] = useApi();

  useEffect(() => {
    setFeed(feed2);
  }, [feed2]);

  const getValue = (y) => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const d = new Date(y);
    let date = d.getDate();
    let hours = d.getHours();
    let min = d.getMinutes();

    let month = months[d.getMonth()];
    let time = `${hours}:${min}`;
    var H = +time.substr(0, 2);
    var h = H % 12 || 12;
    var ampm = H < 12 || H === 24 ? 'AM' : 'PM';
    time = h + time.substr(2, 3) + ampm;
    return `${date} ${month} at ${time}`;
    {
      /* 20 July at 09:00 AM */
    }
    // return `${date} ${month} at ${hours}:${min}`
  };

  const getFile = (user, fileName) => {
    if (!user?.fileArray) return '';
    let z = user.fileArray.filter((x) => x[fileName] && x[fileName].location);
    if (z.length > 0) return z[0][fileName].location;
    return '/assets/img/user_placeholder.svg';
  };

  const handleDelete = (id) => {
    if (confirm('Are you sure you want to delete?')) deletePost(DELETE_FEED(id), 'DELETE');
  };

  useEffect(() => {
    if (!deleteRes.isLoading && !deleteRes.isError && deleteRes.status == 204) {
      refreshData();
      alert('SUCCESS');
    }

    if (!deleteRes.isLoading && deleteRes.isError) {
      alert('FAILED');
    }
  }, [deleteRes.isLoading, deleteRes.isError, deleteRes.status]);

  return (
    <div className="w-full">
      {Array.isArray(feed) &&
        feed.map((x) => (
          <div className=" bg-white rounded-2xl mb-4 flex flex-col shadow-sm overflow-hidden">
            <div className="w-full mb-2 flex items-start">
              <img
                className="w-16 h-16 rounded-br-2xl"
                alt=""
                src={getFile(x?.userId, 'profileImg') || '/assets/img/user_placeholder.svg'}
              />
              <div className="flex flex-col ml-3">
                {/* <p className="text-black mt-1 mb-0 pb-0">{x?.userId?.full_name}</p> */}
                {/* <p className="text-tableRowText text-xs mb-0 pb-0">{x.institute_id.name}</p> */}
                <p className="text-xs font-bold text-green-500 mb-0 pb-0 mt-5">{getValue(x.created)}</p>
              </div>
              <div className="ml-auto mr-5 mt-3.5 cursor-pointer">
                <DropDown
                  data={['Delete']}
                  width={'w-full'}
                  src="/assets/img/options.svg"
                  handleItemClick={(x) => {
                    handleDelete(x?._id);
                  }}
                />
              </div>
            </div>

            <div className="pl-6 pr-4 mt-2">
              <p className="text-lg text-black whitespace-pre-line">{x.summary}</p>
            </div>

            <FlexWrapperTwo>
              {x.images.length !== 0 ? <ImageCarousel images={x.images} /> : null}
              {x.videos.length !== 0 ? <VideoTag alt={x.videos[0].name} src={x.videos[0].location} controls /> : null}
            </FlexWrapperTwo>
          </div>
        ))}
      {/* {post && <ViewPostComponent handleClose={toggleOpenFeed} />} */}
    </div>
  );
}

export default Feeds;

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Components
import Layout from './components/Layout';

// Pages
import Login from './pages/Login';
import Institution from './pages/Institution';
import Staff from './pages/Staff';
import Students from './pages/Students';
import Incident from './pages/Incident';
import ClassSection from './pages/ClassSection';
import Owner from './pages/Owner';
import Principal from './pages/Principal';
import InstituteLeaves from './pages/InstituteLeaves';
import Users from './pages/Users';
import Social from './pages/Social';

import Cookies from 'js-cookie';
import Feedbacks from './pages/Feedbacks';
import FeeStructure from './pages/FeeStructure';
import StudentMapping from './pages/StudentMapping';
import PendingFee from './pages/PendingFee';
import InstituteHoliday from './pages/InstituteHoliday';
import CronJob from './pages/CronJob';
import AIModel from './pages/AIModel';
import SubjectWiseAttendance from "./pages/SubjectWiseAttendance" ;
import UniversityEvents from "./pages/universityEvents" ;

const x = Cookies.get('auth');

const App = () => (
  <Router>
    <Layout>
      <Switch>
        <Route exact path="/">
          {x ? <Institution /> : <Login />}
        </Route>
        <Route exact path="/healthcheck">
          <h2>Hello There!</h2>
        </Route>
        <Route exact path="/institution">
          <Institution />
        </Route>
        <Route exact path="/student">
          <Students />
        </Route>
        <Route exact path="/staff">
          <Staff />
        </Route>
        <Route exact path="/incident">
          <Incident />
        </Route>
        <Route exact path="/class-section">
          <ClassSection />
        </Route>
        <Route exact path="/owner">
          <Owner />
        </Route>
        <Route exact path="/principal">
          <Principal />
        </Route>
        <Route exact path="/institute-leaves">
          <InstituteLeaves />
        </Route>
        <Route exact path="/users">
          <Users />
        </Route>
        <Route exact path="/feedbacks">
          <Feedbacks />
        </Route>
        <Route exact path="/social">
          <Social />
        </Route>
        <Route exact path="/fee-structure">
          <FeeStructure />
        </Route>
        <Route exact path="/student-mapping">
          <StudentMapping />
        </Route>
        <Route exact path="/pendingFee">
          <PendingFee />
        </Route>
        <Route exact path="/institute-holiday">
          <InstituteHoliday />
        </Route>
        <Route exact path="/ai-model">
          <AIModel />
        </Route>
        <Route exact path="/cron-job">
          <CronJob />
        </Route>
        <Route exact path="/subjectWiseAttendance">
          <SubjectWiseAttendance />
        </Route>
        <Route exact path="/university-events">
          <UniversityEvents />
        </Route>
      </Switch>
    </Layout>
  </Router>
);

export default App;

import React, { useEffect, useState } from 'react';
import useApi from '../api/useApi';
import { GET_CREDENTIALS, SEND_EMAIL, GET_INSTITUTES } from '../api/urls';
import DropDown from '../components/UI/DropDownCustom';

function Users() {
  const [users, setUsers] = useState([]);
  const [selectedUser, selectUser] = useState();
  const [instituteRes, getInstitutes] = useApi();
  const [selectedInstitution, selectInstitution] = useState();
  const [institutes, setInstitutes] = useState([]);

  useEffect(() => {
    getInstitutes(GET_INSTITUTES, 'GET');
  }, []);

  useEffect(() => {
    if (!instituteRes.isLoading && !instituteRes.isError && instituteRes.data) {
      setInstitutes(instituteRes.data.response);
    }
  }, [instituteRes]);

  const [userRes, getUsers] = useApi();
  const [emailRes, sendEmail] = useApi();

  useEffect(() => {
    if (selectedInstitution) {
      getUsers(GET_CREDENTIALS(selectedInstitution), 'GET');
      setUsers([]);
    }
  }, [selectedInstitution]);

  useEffect(() => {
    if (!userRes.isLoading && !userRes.isError && userRes.data) {
      setUsers(userRes?.data?.userDetail || []);
    }
  }, [userRes]);

  useEffect(() => {
    if (selectedUser) {
      const body = {
        full_name: selectedUser.fullName,
        username: selectedUser.userName,
        emailId: selectedUser.emailId,
        password: selectedUser.password,
      };
      sendEmail(SEND_EMAIL, 'POST', body);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (!emailRes.isLoading && !emailRes.isError && emailRes.status == 200) {
      alert('Email Sent successfully');
    }

    if (!emailRes.isLoading && emailRes.isError) {
      alert('FAILED');
    }
    selectUser();
  }, [emailRes]);

  return (
    <div className="w-4/5 pb-10">
      <div className="w-full bg-white rounded-xl h-auto mb-4">
        <div className="items-center px-6 py-3 border-b border-borderYellow">
          <div className="flex justify-between items-center">
            <div className="font-bold w-1/3">
              <p>Users</p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap w-full h-1/4 px-6">
          <div className="w-full">
            <DropDown
              width={'w-1/3'}
              title={'Select Institution'}
              data={institutes}
              field={'name'}
              type={'obj'}
              handleItemClick={(x) => {
                selectInstitution(x?._id);
              }}
              margin={'mt-4 mx-auto'}
            />
          </div>
          {userRes.isLoading ? (
            <img src="assets/img/loader.svg" className="w-14 m-auto mt-10" alt="" />
          ) : userRes.data ? (
            <div className="w-full py-4">
              <div className="bg-white m-auto border rounded-2xl border-borderYellow">
                <div className="w-full flex bg-tableHeader rounded-3xl">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium">
                    <div className="w-1/5 border-r-2 py-2 px-2">User Name</div>
                    <div className="w-1/5 border-r-2 py-2 px-2">Name</div>
                    <div className="w-1/5 border-r-2 py-2 px-2">Email</div>
                    <div className="w-1/5 border-r-2 py-2 px-2">Phone</div>
                    <div className="w-1/5 py-2 px-2">Action</div>
                  </div>
                </div>
                <div className="showScroll overflow-auto" style={{ maxHeight: '55vh' }}>
                  {Array.isArray(users) &&
                    users.map((x, idx) => {
                      return (
                        <div className="w-full">
                          <div
                            className={`justify-center flex w-full relative text-left py-4 px-4 text-xs font-medium items-center ${
                              users.length - 1 !== idx && 'border-b border-borderYellow'
                            }`}
                          >
                            <div className="w-1/5 px-2 text-tableRowText overflow-ellipsis overflow-hidden whitespace-nowrap">
                              {x?.userName}
                            </div>
                            <div className="w-1/5 px-2 text-tableRowText overflow-ellipsis overflow-hidden whitespace-nowrap">
                              {x?.fullName}
                            </div>
                            <div className="w-1/5 px-2 text-tableRowText overflow-ellipsis overflow-hidden whitespace-nowrap">
                              {x?.emailId}
                            </div>
                            <div className="w-1/5 px-2 text-tableRowText overflow-ellipsis overflow-hidden whitespace-nowrap">
                              {x?.password}
                            </div>

                            <div className={`w-1/5 px-2 gap-x-2 flex`}>
                              {emailRes.isLoading && selectedUser?.fullName === x?.fullName ? (
                                <img src="assets/img/loader.svg" className="w-14 m-auto mt-10" alt="" />
                              ) : (
                                <button
                                  className="w-11/12 border py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 bg-primary border-primary text-white"
                                  onClick={() => {
                                    selectUser(x);
                                  }}
                                >
                                  Send Email
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          ) : (
            <p className="w-full text-center text-xl font-semibold my-10 text-primary">
              Select Institution To Get Student Data
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Users;

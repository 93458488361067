import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useApi from '../../api/useApi';
import { GET_CLASSES, GET_SECTIONS, UPDATE_USER } from '../../api/urls';
import DropDown from '../UI/DropDownCustom';
import { getFileInfo } from 'prettier';

function AddStudent({ toggleView, selectedStudent, refreshList, selectedInstitution }) {
  const [step, setStep] = useState(0);
  const [medicalCert, setMedicalCert] = useState();
  const [transferCertificate, setTransferCertificate] = useState();
  const [provisionalCertificate, setProvisionalCertificate] = useState();
  const [otherCertificate, setOtherCertificate] = useState();
  const [supportingDocument, setSupportingDocument] = useState();
  const [profileImg, setProfileImg] = useState();
  const [image, setImage] = useState('/assets/img/user_placeholder.svg');
  const [markSheet, setMarkSheet] = useState();
  const [addressProof, setAddress] = useState();
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [selectedClass, selectClass] = useState();
  const [selectedSection, selectSection] = useState();

  const [classesRes, getClasses] = useApi();
  const [sectionsRes, getSections] = useApi();

  useEffect(() => {
    if (selectedInstitution) getClasses(GET_CLASSES(selectedInstitution), 'GET');
  }, [selectedInstitution]);

  useEffect(() => {
    if (selectedInstitution && selectedClass) getSections(GET_SECTIONS(selectedInstitution, selectedClass._id), 'GET');
  }, [selectedInstitution, selectedClass]);

  useEffect(() => {
    if (!classesRes.isLoading && !classesRes.isError && classesRes.data) {
      setClasses(classesRes.data);
    }
  }, [classesRes]);

  useEffect(() => {
    if (!sectionsRes.isLoading && !sectionsRes.isError && sectionsRes.data) {
      setSections(sectionsRes.data);
    }
  }, [sectionsRes]);

  const medicalRef = useRef();
  const markSheetRef = useRef();
  const transferRef = useRef();
  const provisionalRef = useRef();
  const otherRef = useRef();
  const supportingRef = useRef();
  const addressRef = useRef();
  const imgRef = useRef();

  useEffect(() => {
    if (profileImg) {
      setImage(URL.createObjectURL(profileImg));
    }
  }, [profileImg]);

  const infoModel = {
    full_name: '',
    gender: '',
    placeOfBirth: '',
    dateOfBirth: '',
    class: '',
    section: '',
    // aadhar: '',
    bloodgrp: '',
    roleType: 'st',
    role: 'student',
    rollNumber: '',
  };

  const parentModel = {
    companyName: '',
    designation: '',
    officeAddress: '',
    phone: '',
    email_Id: '',
    role: 'parent',
  };

  const addressModel = {
    hno: '',
    society: '',
    district: '',
    city: '',
    state: '',
    pincode: '',
  };

  const lastSchoolModel = {
    schoolName: '',
    class: '',
    address: '',
    medium: '',
  };

  const feeModel = {
    amount: '',
    paidVia: '',
    challanNo: '',
  };

  const [infoState, setInfoState] = useState(infoModel);
  const [addressState, setAddressState] = useState(addressModel);
  const [caddressState, setcAddressState] = useState(addressModel);
  const [fatherState, setFatherState] = useState({ father_name: '', ...parentModel });
  const [motherState, setMotherState] = useState({ mother_name: '', ...parentModel });
  const [guardianState, setGuardianState] = useState({ guardian_name: '', ...parentModel });
  const [lastSchoolState, setLastSchoolState] = useState(lastSchoolModel);
  const [feeState, setFeeState] = useState(feeModel);

  const [isFatherChecked, checkFather] = useState(true);
  const [isMotherChecked, checkMother] = useState(false);
  const [isGuardianChecked, checkGuardian] = useState(false);
  const [isSameAddress, setSameAddress] = useState(false);

  const handleInfoChange = (e) => setInfoState({ ...infoState, [e.target.name]: e.target.value });

  const handleAddressInputChange = (e) => setAddressState({ ...addressState, [e.target.name]: e.target.value });

  const handlecAddressInputChange = (e) => setcAddressState({ ...caddressState, [e.target.name]: e.target.value });

  const handleLastSchoolChange = (e) => setLastSchoolState({ ...lastSchoolState, [e.target.name]: e.target.value });

  const handleFatherChange = (e) => setFatherState({ ...fatherState, [e.target.name]: e.target.value });

  const handleMotherChange = (e) => setMotherState({ ...motherState, [e.target.name]: e.target.value });

  const handGuardianChange = (e) => setGuardianState({ ...guardianState, [e.target.name]: e.target.value });

  const handleFeeChange = (e) => setFeeState({ ...feeState, [e.target.name]: e.target.value });

  const [{ isLoading, isError, data, status }, createStaff] = useApi();

  useEffect(() => {
    if (isSameAddress) {
      setcAddressState({ ...addressState });
    } else {
      setcAddressState(addressModel);
    }
  }, [isSameAddress]);

  useEffect(() => {
    if (selectedStudent && typeof selectedStudent === 'object') {
      setInfoState({ ...selectedStudent, rollNumber: selectedStudent.currentlyMapped?.rollNumber });
      setAddressState(selectedStudent.address);
      setcAddressState(selectedStudent.caddress);
      setLastSchoolState(selectedStudent.lastSchool);

      if (selectedStudent.parentDetail?.length > 0) {
        selectedStudent.parentDetail.forEach((x) => {
          if ('father_name' in x) {
            setFatherState(x);
          } else if ('mother_name' in x) {
            setMotherState(x);
          } else if ('guardian_name' in x) setGuardianState(x);
        });
      }

      // setFeeState(selectedStudent);
      if (selectedStudent.fileArray.length > 0) {
        setImage(getFile('profileImg'));
      }
    }
  }, [selectedStudent]);

  const getFile = (fileName) => {
    let z = selectedStudent.fileArray.filter((x) => x[fileName] && x[fileName].location);
    if (z.length > 0) return z[0][fileName].location;
    return '';
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const parentDetail = [];
    if (fatherState.father_name) parentDetail.push(fatherState);
    if (motherState.mother_name) parentDetail.push(motherState);
    if (guardianState.guardian_name) parentDetail.push(guardianState);

    const formData = new FormData();
    formData.append('instituteId', selectedInstitution);
    formData.append('markSheet', markSheet);
    formData.append('medicalCertificate', medicalCert);
    formData.append('transferCertificate', transferCertificate);
    formData.append('provisionalCertificate', provisionalCertificate);
    formData.append('otherCertificate', otherCertificate);
    formData.append('supportingDocument', supportingDocument);
    formData.append('profileImg', profileImg);
    formData.append('addressProof', addressProof);

    formData.append('address', JSON.stringify(addressState));
    formData.append('caddress', JSON.stringify(caddressState));
    formData.append('lastSchool', JSON.stringify(lastSchoolState));
    formData.append('parentDetail', JSON.stringify(parentDetail));
    formData.append('full_name', infoState.full_name);
    formData.append('gender', infoState.gender);
    formData.append('placeOfBirth', infoState.placeOfBirth);
    formData.append('dateOfBirth', infoState.dateOfBirth);
    formData.append('class', infoState.class);
    formData.append('section', infoState.section);
    // formData.append('aadhar', infoState.aadhar);
    formData.append('bloodgrp', infoState.bloodgrp);
    formData.append('roleType', 'st');
    formData.append('role', 'student');
    formData.append('rollNumber', infoState.rollNumber);

    createStaff(UPDATE_USER(selectedStudent._id), 'PUT', formData);
  };

  useEffect(() => {
    if (!isLoading && !isError && status) {
      setInfoState(infoModel);
      setAddressState(addressModel);
      setcAddressState(addressModel);
      setLastSchoolState(lastSchoolModel);
      setFeeState(feeModel);
      setFatherState({ father_name: '', ...parentModel });
      setMotherState({ mother_name: '', ...parentModel });
      setGuardianState({ guardian_name: '', ...parentModel });
      toggleView();
      refreshList();
    }
  }, [isLoading, isError, data, status]);

  const getDateFormat = (x) => {
    if (!x) return null;
    const y = new Date(x);
    return `${y.getDate()}-${y.getMonth() + 1}-${y.getFullYear()}`;
  };

  return (
    <div
      className="pb-10 w-full fixed overflow-auto left-0 bottom-0 top-0 z-30"
      style={{
        background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))',
      }}
    >
      <div className="relative w-4/5 pb-6 mx-auto h-auto mt-6 bg-white rounded-2xl">
        <div className="text-sm block relative border-b border-borderYellow">
          <div className="flex justify-between items-center py-3">
            <div className="font-bold px-8">
              <p>Add Student</p>
            </div>
            <div className="flex px-8 gap-x-8 items-center">
              <img src="assets/img/Close.svg" className=" h-3 cursor-pointer" alt="" onClick={toggleView} />
            </div>
          </div>
        </div>

        <div className="py-2 px-8 relative grid grid-cols-4 gap-x-4 border-b border-borderYellow">
          <button
            onClick={() => setStep(0)}
            className={`py-2 w-full rounded-full text-xs ${
              step === 0 ? 'bg-primary border-primary text-white' : 'bg-white text-black'
            }`}
          >
            Student Information
          </button>
          <button
            onClick={() => setStep(1)}
            className={`py-2 w-full rounded-full text-xs ${
              step === 1 ? 'bg-primary border-primary text-white' : 'bg-white text-black'
            }`}
          >
            Parents Information
          </button>
          <button
            onClick={() => setStep(2)}
            className={`py-2 w-full rounded-full text-xs ${
              step === 2 ? 'bg-primary border-primary text-white' : 'bg-white text-black'
            }`}
          >
            Address
          </button>
          <button
            onClick={() => setStep(3)}
            className={`py-2 w-full rounded-full text-xs ${
              step === 3 ? 'bg-primary border-primary text-white' : 'bg-white text-black'
            }`}
          >
            Last School
          </button>
          {/* <button
            onClick={() => setStep(4)}
            className={`py-2 w-full rounded-full text-xs ${
              step === 4 ? 'bg-primary border-primary text-white' : 'bg-white text-black'
            }`}
          >
            Admission Fee
          </button> */}
        </div>

        {step === 0 && (
          <form autoComplete="off" className="px-8" onSubmit={() => setStep(1)}>
            <div className="items-center py-4 ">
              <p className=" font-medium ">Student Information</p>
            </div>

            <div className="absolute right-6 -mt-8">
              <span className="text-red-500 ml-1 text-xs font-semibold">
                <span className="ml-1 text-lg ">* </span>Mandatory Fields
              </span>
            </div>

            <div className="flex w-full">
              <div>
                <div className="profileLogo  bg-gray-500 w-56 h-56 relative">
                  {image && <img src={image} alt=" " className="h-full w-full profileLogo" />}
                  <input hidden ref={imgRef} type="file" onChange={(e) => setProfileImg(e.target.files[0])} />
                  <img
                    src="assets/img/camera.png"
                    className="w-10 h-10 absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-1/2"
                    onClick={(e) => {
                      e.preventDefault();
                      imgRef.current.click();
                    }}
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-x-4 pb-4 w-full pl-12">
                <div className="my-2 w-full relative font-semibold ">
                  <label htmlFor="category" className="text-textDark font-medium text-sm">
                    Full Name<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    // placeholder="Eg-Rakesh Ahirwar"
                    value={infoState.full_name}
                    name="full_name"
                    onChange={handleInfoChange}
                    autoComplete="off"
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold ">
                  <label htmlFor="budget" className="text-textDark font-medium text-sm">
                    Gender<span className="text-red-500 ml-1">*</span>
                  </label>
                  <DropDown
                    data={['Male', 'Female']}
                    handleItemClick={(x) => {
                      x && handleInfoChange({ target: { value: x, name: 'gender' } });
                    }}
                    title={selectedStudent ? infoState.gender : 'Select'}
                    width={'w-full'}
                    otherClasses={'border-borderLight mt-2 text-textDark'}
                  />
                </div>
                <div className="my-2 w-full relative font-semibold ">
                  <label htmlFor="budget" className="text-textDark font-medium text-sm">
                    Place of Birth<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    // placeholder="Eg-Male"
                    value={infoState.placeOfBirth}
                    name="placeOfBirth"
                    onChange={handleInfoChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold flex flex-col">
                  <label htmlFor="budget" className="text-textDark font-medium text-sm">
                    Date of Birth<span className="text-red-500 ml-1">*</span>
                  </label>
                  {/* {selectedStudent ? (
                    <p className="w-full rounded-3xl border font-normal border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none cur">
                      {moment(infoState.dateOfBirth).format('d MMMM YYYY')}
                    </p>
                  ) : ( */}
                  <DatePicker
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none cur"
                    selected={infoState.dateOfBirth && new Date(infoState.dateOfBirth)}
                    onChange={(date) => setInfoState({ ...infoState, dateOfBirth: date })}
                    dateFormat={'dd-MM-yyyy'}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    required
                  />
                  {/* )} */}
                </div>

                <div className="my-2 w-full relative font-semibold ">
                  <label className="text-textDark font-medium text-sm">Roll No.</label>
                  <input
                    type="number"
                    // placeholder="Eg-Male"
                    value={infoState?.rollNumber}
                    name="rollNumber"
                    onChange={handleInfoChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>
                {/* <div className="my-2 w-full relative font-semibold ">
                  <label htmlFor="budget" className="text-textDark font-medium text-sm">
                    Class<span className="text-red-500 ml-1">*</span>
                  </label>
                  <DropDown
                    field={'name'}
                    data={classes}
                    type={'obj'}
                    title={selectedStudent ? infoState.class || '--' : 'Select'}
                    handleItemClick={(x) => {
                      handleInfoChange({ target: { value: x._id, name: 'class' } });
                      selectClass(x);
                    }}
                    width={'w-full'}
                    otherClasses={'border-borderLight mt-2'}
                  />
                </div>
                <div className="my-2 w-full relative font-semibold ">
                  <label htmlFor="budget" className="text-textDark font-medium text-sm">
                    Section<span className="text-red-500 ml-1">*</span>
                  </label>
                  <DropDown
                    data={sections}
                    type={'obj'}
                    field={'name'}
                    title={selectedStudent ? infoState.section || '--' : 'Select'}
                    handleItemClick={(x) => {
                      handleInfoChange({ target: { value: x._id, name: 'section' } });
                    }}
                    width={'w-full'}
                    otherClasses={'border-borderLight mt-2'}
                  />
                </div> */}
                {/* <div className="my-2 w-full relative font-semibold ">
                  <label htmlFor="budget" className="text-textDark font-medium text-sm">
                    AADHAR<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    // placeholder="Eg-19 may-1992"
                    value={infoState.aadhar}
                    name="aadhar"
                    onChange={handleInfoChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div> */}
                <div className="my-2 w-full relative font-semibold ">
                  <label htmlFor="budget" className="text-textDark font-medium text-sm">
                    Blood Group<span className="text-red-500 ml-1">*</span>
                  </label>
                  <DropDown
                    data={[
                      'A positive',
                      'A negative',
                      'B positive',
                      'B negative',
                      'AB positive',
                      'AB negative',
                      'O positive',
                      'O negative',
                    ]}
                    title={selectedStudent ? infoState.bloodgrp : 'Select'}
                    handleItemClick={(x) => {
                      x && handleInfoChange({ target: { value: x, name: 'bloodgrp' } });
                    }}
                    width={'w-full'}
                    otherClasses={'border-borderLight mt-2'}
                  />
                </div>
                <div className="my-2 w-full relative font-semibold ">
                  <label htmlFor="budget" className="text-textDark font-medium text-sm">
                    Medical Certificate
                    {/* <span className="text-red-500 ml-1">*</span> */}
                  </label>
                  <div className="relative flex">
                    <input
                      hidden
                      ref={medicalRef}
                      type="file"
                      onChange={(e) => setMedicalCert(e.target.files[0])}
                      className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                    />
                    <input
                      // required
                      type="text"
                      disabled
                      value={medicalCert?.name}
                      className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                    />
                    <button
                      className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center h-9 mt-2 absolute right-0"
                      onClick={(e) => {
                        e.preventDefault();
                        medicalRef.current.click();
                      }}
                    >
                      Upload
                    </button>
                  </div>
                  {getFile('medicalCertificate') && (
                    <a className="text-blue-700 underline" href={getFile('medicalCertificate')} download>
                      <p className="text-primary underline">Download Medical Certificate</p>
                    </a>
                  )}
                </div>
              </div>
            </div>

            <div className="flex gap-x-4 justify-end">
              {/* <button
                className="w-40 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                onClick={() => {}}
              >
                Save
              </button> */}
              <button
                className="w-40 border px-8 py-2 rounded-3xl text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center"
                type="submit"
                // onClick={() => {
                //   setStep(1);
                // }}
              >
                Next
              </button>
            </div>
          </form>
        )}

        {step === 1 && (
          <form
            autoComplete="off "
            onSubmit={() => {
              setStep(2);
            }}
          >
            <p className=" font-bold text-black pt-4 px-8">Father</p>

            <div className="absolute right-6 -mt-4 flex justify-center items-center">
              <input
                type="checkbox"
                checked={isFatherChecked}
                onChange={() => checkFather((prev) => !prev)}
                className="mr-2"
              />
              <span className="text-tableRowText ml-1 text-xs font-semibold">Add Father’s Detail</span>
            </div>

            <div className="grid grid-cols-2 gap-x-4 pb-4 w-full border-b border-borderYellow px-8">
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="category" className="text-textDark font-medium text-sm">
                  Name<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  required={isFatherChecked}
                  // placeholder="Eg-198"
                  value={fatherState.father_name}
                  name="father_name"
                  onChange={handleFatherChange}
                  autoComplete="off"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Company Name<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  required={isFatherChecked}
                  // placeholder="Eg-Amar Colony"
                  value={fatherState.companyName}
                  name="companyName"
                  onChange={handleFatherChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Designation
                </label>
                <input
                  type="text"
                  // placeholder="Eg-Jalandhar"
                  value={fatherState.designation}
                  name="designation"
                  onChange={handleFatherChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Office Address<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  required={isFatherChecked}
                  // placeholder="Eg-Jalandhar"
                  value={fatherState.officeAddress}
                  name="officeAddress"
                  onChange={handleFatherChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Contact Number<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  required={isFatherChecked}
                  // placeholder="Eg-Punjab"
                  value={fatherState.phone}
                  name="phone"
                  onChange={handleFatherChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Email<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  required={isFatherChecked}
                  // placeholder="Eg-141401"
                  value={fatherState.email_Id}
                  name="email_Id"
                  onChange={handleFatherChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
            </div>

            <p className=" font-bold text-black pt-4 px-8">Mother</p>

            <div className="absolute right-6 -mt-4 flex justify-center items-center">
              <input
                type="checkbox"
                checked={isMotherChecked}
                onChange={() => checkMother((prev) => !prev)}
                className="mr-2"
              />
              <span className="text-tableRowText ml-1 text-xs font-semibold">Add Mother’s Detail</span>
            </div>

            <div className="grid grid-cols-2 gap-x-4 pb-4 w-full border-b border-borderYellow px-8">
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="category" className="text-textDark font-medium text-sm">
                  Name<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  required={isMotherChecked}
                  // placeholder="Eg-198"
                  value={motherState.mother_name}
                  name="mother_name"
                  onChange={handleMotherChange}
                  autoComplete="off"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Company Name<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  required={isMotherChecked}
                  // placeholder="Eg-Amar Colony"
                  value={motherState.companyName}
                  name="companyName"
                  onChange={handleMotherChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Designation
                </label>
                <input
                  type="text"
                  // placeholder="Eg-Jalandhar"
                  value={motherState.designation}
                  name="designation"
                  onChange={handleMotherChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Office Address<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  required={isMotherChecked}
                  // placeholder="Eg-Jalandhar"
                  value={motherState.officeAddress}
                  name="officeAddress"
                  onChange={handleMotherChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Contact Number<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  required={isMotherChecked}
                  // placeholder="Eg-Punjab"
                  value={motherState.phone}
                  name="phone"
                  onChange={handleMotherChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Email<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  required={isMotherChecked}
                  // placeholder="Eg-141401"
                  value={motherState.email_Id}
                  name="email_Id"
                  onChange={handleMotherChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
            </div>

            <p className=" font-bold text-black pt-4 px-8">Guardian</p>

            <div className="absolute right-6 -mt-4 flex justify-center items-center">
              <input
                type="checkbox"
                checked={isGuardianChecked}
                onChange={() => checkGuardian((prev) => !prev)}
                className="mr-2"
              />
              <span className="text-tableRowText ml-1 text-xs font-semibold">Add Guardian’s Detail</span>
            </div>

            <div className="grid grid-cols-2 gap-x-4 pb-4 w-full  px-8">
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="category" className="text-textDark font-medium text-sm">
                  Name<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  required={isGuardianChecked}
                  // placeholder="Eg-198"
                  value={guardianState.guardian_name}
                  name="guardian_name"
                  onChange={handGuardianChange}
                  autoComplete="off"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Company Name<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  required={isGuardianChecked}
                  // placeholder="Eg-Amar Colony"
                  value={guardianState.companyName}
                  name="companyName"
                  onChange={handGuardianChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Designation
                </label>
                <input
                  type="text"
                  // placeholder="Eg-Jalandhar"
                  value={guardianState.designation}
                  name="designation"
                  onChange={handGuardianChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Office Address<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  required={isGuardianChecked}
                  // placeholder="Eg-Jalandhar"
                  value={guardianState.officeAddress}
                  name="officeAddress"
                  onChange={handGuardianChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Contact Number<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  required={isGuardianChecked}
                  // placeholder="Eg-Punjab"
                  value={guardianState.phone}
                  name="phone"
                  onChange={handGuardianChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Email<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  required={isGuardianChecked}
                  // placeholder="Eg-141401"
                  value={guardianState.email_Id}
                  name="email_Id"
                  onChange={handGuardianChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
            </div>

            <div className="flex gap-x-4 justify-end px-8">
              {/* <button
                className="w-40 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                onClick={() => {}}
              >
                Save
              </button> */}
              <button
                type="submit"
                className="w-40 border px-8 py-2 rounded-3xl text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center"
                // onClick={() => {
                //   setStep(2);
                // }}
              >
                Next
              </button>
            </div>
          </form>
        )}

        {step === 2 && (
          <form autoComplete="off" onSubmit={() => setStep(3)}>
            <p className=" font-bold text-black pt-4 px-8">Permanent Address</p>
            <div className="grid grid-cols-2 gap-x-4 pb-4 w-full border-b border-borderYellow px-8">
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="category" className="text-textDark font-medium text-sm">
                  Flat No. / House No.<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  required
                  // placeholder="Eg-198"
                  value={addressState?.hno}
                  name="hno"
                  onChange={handleAddressInputChange}
                  autoComplete="off"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Colony / Society<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  required
                  // placeholder="Eg-Amar Colony"
                  value={addressState?.society}
                  name="society"
                  onChange={handleAddressInputChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  District<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  required
                  type="text"
                  // placeholder="Eg-Jalandhar"
                  value={addressState?.district}
                  name="district"
                  onChange={handleAddressInputChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  City<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  required
                  // placeholder="Eg-Jalandhar"
                  value={addressState?.city}
                  name="city"
                  onChange={handleAddressInputChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  State<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  required
                  // placeholder="Eg-Punjab"
                  value={addressState?.state}
                  name="state"
                  onChange={handleAddressInputChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Pin Code<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  required
                  // placeholder="Eg-141401"
                  value={addressState?.pincode}
                  name="pincode"
                  onChange={handleAddressInputChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Address Proof Document
                </label>
                <div className="relative flex">
                  <input hidden ref={addressRef} type="file" onChange={(e) => setAddress(e.target.files[0])} />
                  <input
                    // required
                    type="text"
                    disabled
                    value={addressProof?.name}
                    className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />

                  <button
                    className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center h-9 mt-2 absolute right-0"
                    onClick={(e) => {
                      e.preventDefault();
                      addressRef.current.click();
                    }}
                  >
                    Upload
                  </button>
                </div>
                {getFile('addressProof') && (
                  <a className="text-blue-700 underline" href={getFile('addressProof')} download>
                    <p className="text-primary underline">Download Adress Proof</p>
                  </a>
                )}
              </div>

              {/* <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Upload Document
                </label>
                <div className="relative flex">
                  <input
                    // required
                    type="file"
                    // placeholder="Ex- Syllabus"
                    className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                  <button
                    className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center h-9 mt-2 absolute right-0"
                    // onClick={() => setCreateTest(true)}
                  >
                    Upload
                  </button>
                </div>
              </div> */}
            </div>

            <p className=" font-bold text-black pt-4 px-8">Correspondance Address</p>

            <div className="absolute right-6 -mt-4 flex justify-center items-center">
              <input
                type="checkbox"
                checked={isSameAddress}
                onChange={() => setSameAddress((prev) => !prev)}
                className="mr-2"
              />
              <span className="text-tableRowText ml-1 text-xs font-semibold">Same as permanent address</span>
            </div>

            <div className="grid grid-cols-2 gap-x-4 pb-4 w-full px-8">
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="category" className="text-textDark font-medium text-sm">
                  Flat No. / House No.
                </label>
                <input
                  type="text"
                  // placeholder="Eg-198"
                  value={caddressState?.hno}
                  name="hno"
                  onChange={handlecAddressInputChange}
                  autoComplete="off"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Colony / Society
                </label>
                <input
                  type="text"
                  // placeholder="Eg-Amar Colony"
                  value={caddressState?.society}
                  name="society"
                  onChange={handlecAddressInputChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  District
                </label>
                <input
                  type="text"
                  // placeholder="Eg-Jalandhar"
                  value={caddressState?.district}
                  name="district"
                  onChange={handlecAddressInputChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  City
                </label>
                <input
                  type="text"
                  // placeholder="Eg-Jalandhar"
                  value={caddressState?.city}
                  name="city"
                  onChange={handlecAddressInputChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  State
                </label>
                <input
                  type="text"
                  // placeholder="Eg-Punjab"
                  value={caddressState?.state}
                  name="state"
                  onChange={handlecAddressInputChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Pin Code
                </label>
                <input
                  type="text"
                  // placeholder="Eg-141401"
                  value={caddressState?.pincode}
                  name="pincode"
                  onChange={handlecAddressInputChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              {/* <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Address Proof Document
                </label>
                <div className="relative flex">
                  <input
                    // required
                    type="file"
                    // placeholder="Ex- Syllabus"
                    className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                  <button
                    className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center h-9 mt-2 absolute right-0"
                    // onClick={() => setCreateTest(true)}
                  >
                    Upload
                  </button>
                </div>
              </div> */}

              {/* <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Upload Document
                </label>
                <div className="relative flex">
                  <input
                    // required
                    type="file"
                    // placeholder="Ex- Syllabus"
                    className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                  <button
                    className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center h-9 mt-2 absolute right-0"
                    // onClick={() => setCreateTest(true)}
                  >
                    Upload
                  </button>
                </div>
              </div> */}
            </div>

            <div className="flex gap-x-4 justify-end px-8">
              {/* <button
                className="w-40 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                onClick={() => {}}
              >
                Save
              </button> */}
              <button
                type="submit"
                className="w-40 border px-8 py-2 rounded-3xl text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center"
                // onClick={() => {
                //   setStep(3);
                // }}
              >
                Next
              </button>
            </div>
          </form>
        )}

        {step === 3 && (
          <form autoComplete="off " onSubmit={handleSubmit}>
            <p className=" font-bold text-black pt-4 px-8">Last School</p>
            <div className="grid grid-cols-2 gap-x-4 pb-4 w-full border-b border-borderYellow px-8">
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="category" className="text-textDark font-medium text-sm">
                  School Name<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  required
                  // placeholder="Eg-198"
                  value={lastSchoolState?.schoolName}
                  name="schoolName"
                  onChange={handleLastSchoolChange}
                  autoComplete="off"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Class<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  required
                  // placeholder="Eg-Amar Colony"
                  value={lastSchoolState?.class}
                  name="class"
                  onChange={handleLastSchoolChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Address<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  required
                  // placeholder="Eg-Jalandhar"
                  value={lastSchoolState?.address}
                  name="address"
                  onChange={handleLastSchoolChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Medium<span className="text-red-500 ml-1">*</span>
                </label>
                <DropDown
                  data={['English', 'Hindi']}
                  handleItemClick={(x) => {
                    x && handleLastSchoolChange({ target: { value: x, name: 'medium' } });
                  }}
                  title={selectedStudent ? lastSchoolState?.medium : 'Select'}
                  width={'w-full'}
                  otherClasses={'border-borderLight mt-2 text-textDark'}
                />
              </div>
            </div>

            <p className=" font-bold text-black pt-4 px-8">Documents</p>
            <div className="grid grid-cols-2 gap-x-4 pb-4 w-full px-8">
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="category" className="text-textDark font-medium text-sm">
                  Marksheet<span className="text-red-500 ml-1">*</span>
                </label>
                <div className="relative flex">
                  <input
                    hidden
                    ref={markSheetRef}
                    type="file"
                    onChange={(e) => setMarkSheet(e.target.files[0])}
                    className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                  <input
                    // required
                    type="text"
                    disabled
                    value={markSheet?.name}
                    className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                  <button
                    className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center h-9 mt-2 absolute right-0"
                    onClick={(e) => {
                      e.preventDefault();
                      markSheetRef.current.click();
                    }}
                  >
                    Upload
                  </button>
                </div>
                {getFile('markSheet') && (
                  <a className="text-blue-700 underline" href={getFile('markSheet')} download>
                    <p className="text-primary underline">Download Mark Shaeet</p>
                  </a>
                )}
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Transfer Certificate<span className="text-red-500 ml-1">*</span>
                </label>
                <div className="relative flex">
                  <input
                    hidden
                    ref={transferRef}
                    type="file"
                    onChange={(e) => setTransferCertificate(e.target.files[0])}
                    className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                  <input
                    // required
                    type="text"
                    disabled
                    value={transferCertificate?.name}
                    className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                  <button
                    className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center h-9 mt-2 absolute right-0"
                    onClick={(e) => {
                      e.preventDefault();
                      transferRef.current.click();
                    }}
                  >
                    Upload
                  </button>
                </div>
                {getFile('transferCertificate') && (
                  <a className="text-blue-700 underline" href={getFile('transferCertificate')} download>
                    <p className="text-primary underline">Download Transfer Certificate</p>
                  </a>
                )}
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Provisional Certificate<span className="text-red-500 ml-1">*</span>
                </label>
                <div className="relative flex">
                  <input
                    hidden
                    ref={provisionalRef}
                    type="file"
                    onChange={(e) => setProvisionalCertificate(e.target.files[0])}
                    className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                  <input
                    // required
                    type="text"
                    disabled
                    value={provisionalCertificate?.name}
                    className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                  <button
                    className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center h-9 mt-2 absolute right-0"
                    onClick={(e) => {
                      e.preventDefault();
                      provisionalRef.current.click();
                    }}
                  >
                    Upload
                  </button>
                </div>
                {getFile('provisionalCertificate') && (
                  <a className="text-blue-700 underline" href={getFile('provisionalCertificate')} download>
                    <p className="text-primary underline">Download Provisional Certificate</p>
                  </a>
                )}
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Other Certificate<span className="text-red-500 ml-1">*</span>
                </label>
                <div className="relative flex">
                  <input
                    hidden
                    ref={otherRef}
                    type="file"
                    onChange={(e) => setOtherCertificate(e.target.files[0])}
                    className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                  <input
                    // required
                    type="text"
                    disabled
                    value={otherCertificate?.name}
                    className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                  <button
                    className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center h-9 mt-2 absolute right-0"
                    onClick={(e) => {
                      e.preventDefault();
                      otherRef.current.click();
                    }}
                  >
                    Upload
                  </button>
                </div>
                {getFile('otherCertificate') && (
                  <a className="text-blue-700 underline" href={getFile('otherCertificate')} download>
                    <p className="text-primary underline">Download Other Certificate</p>
                  </a>
                )}
              </div>
            </div>

            <div className="flex gap-x-4 justify-end px-8">
              {/* <button
                className="w-40 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                onClick={() => {}}
              >
                Save
              </button> */}
              {isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
              ) : (
                <button
                  type="submit"
                  className="w-40 border px-8 py-2 rounded-3xl text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center"
                  // onClick={() => {
                  //   setStep(4);
                  // }}
                >
                  Save
                </button>
              )}
            </div>
          </form>
        )}

        {/* {step === 4 && (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-x-4 pb-4 w-full px-8 mt-4">
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="category" className="text-textDark font-medium text-sm">
                  Total Admission Fee Amount<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  required
                  // placeholder="Eg-198"
                  value={feeState.amount}
                  name="amount"
                  onChange={handleFeeChange}
                  autoComplete="off"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Paid Via<span className="text-red-500 ml-1">*</span>
                </label>
                <DropDown
                  data={['Challan', 'Online']}
                  handleItemClick={(x) => {
                    handleFeeChange({ target: { value: x, name: 'paidVia' } });
                  }}
                  title={selectedStudent ? feeState.paidVia : 'Select'}
                  width={'w-full'}
                  otherClasses={'border-borderLight mt-2 text-textDark'}
                />
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Challan Number<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  required
                  // placeholder="Eg-Jalandhar"
                  value={feeState.challanNo}
                  name="challanNo"
                  onChange={handleFeeChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Supporting Document<span className="text-red-500 ml-1">*</span>
                </label>
                <div className="relative flex">
                  <input
                    hidden
                    ref={supportingRef}
                    type="file"
                    onChange={(e) => setSupportingDocument(e.target.files[0])}
                    className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                  <input
                    // required
                    type="text"
                    disabled
                    value={supportingDocument?.name}
                    className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                  <button
                    className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center h-9 mt-2 absolute right-0"
                    onClick={(e) => {
                      e.preventDefault();
                      supportingRef.current.click();
                    }}
                  >
                    Upload
                  </button>
                </div>
              </div>
            </div>

            <div className="flex gap-x-4 justify-end px-8">
              {isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
              ) : (
                <button
                  className="w-40 border px-8 py-2 rounded-3xl text-xs text-white bg-primary focus:outline-none flex justify-center items-center"
                  type="submit"
                >
                  {selectedStudent ? 'Update' : 'Submit'}
                </button>
              )}
            </div>
          </form>
        )} */}
      </div>
    </div>
  );
}

export default AddStudent;

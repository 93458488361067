import React, { useState, useEffect, useRef } from 'react';

import useApi from '../../api/useApi';
import { POST_INSTITUTES, UPDATE_INSTITUTE } from '../../api/urls';
import { useStateValue } from '../../ContextApi/StateProvider';

function CreateInstitution({ handleClose, refreshList, selectedInstitution }) {
  const [{ masterData, universityData }, dispatch] = useStateValue();

  const formDataModel = {
    name: '',
    address: '',
    phone: '',
    slug: '',
    url: '',
    about: '',
    financeCycle: 1,
    type: '',
    logo: null,
    cover: null,
    transportFee: 'Fixed',
    board: '',
    university:"",
    state: '',
    district: '',
  };

  const logoRef = useRef();
  const coverRef = useRef();

  const [formData, setFormData] = useState(formDataModel);
  const [isUniversity, setIsUniversity] = useState(false);

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });
  const handleTypeChange = (e) => { 
    let InstituteType = e.target.value ; 
    // console.log(" -- InstituteType : ", InstituteType );
    let setData = {}
    if( InstituteType == "College" ){ setIsUniversity(true); setData = { board: "" };  }
    else{ setIsUniversity(false);  setData = { university: "" } }
    setFormData({ ...formData, [e.target.name]: e.target.value, ...setData  });
  }

  const [{ isLoading, isError, data, status }, createInstitution] = useApi();

  useEffect(() => {
    if (selectedInstitution) {
      setFormData(selectedInstitution);
    }
  }, [selectedInstitution]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // const body = {
    //   ...formData,
    // };
    const body = new FormData();
    body.append('name', formData.name);
    body.append('address', formData.address);
    body.append('phone', formData.phone);
    body.append('slug', formData.slug);
    body.append('url', formData.url);
    body.append('about', formData.about);
    body.append('type', formData.type);
    body.append('logo', formData.logo);
    body.append('cover', formData.cover);
    body.append('financeCycle', formData.financeCycle);
    body.append('transportFee', formData.transportFee);
    body.append('board', formData.board);
    body.append('university', formData.university);
    body.append('state', formData.state);
    body.append('district', formData.district);

    if (selectedInstitution) createInstitution(UPDATE_INSTITUTE(selectedInstitution._id), 'PUT', body);
    else createInstitution(POST_INSTITUTES, 'POST', body);
  };

  useEffect(() => {
    if (!isLoading && !isError && status == 201) {
      setFormData(formDataModel);
      handleClose();
      refreshList();
      alert('SUCCESS');
    }

    if (!isLoading && isError) {
      alert('FAILED');
    }
  }, [isLoading, isError, data, status]);
  // console.log(" -- universityData : ", universityData ) ;
  // console.log(" -- formData : ", formData ) ;

  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30 overflow-auto py-6"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-3/5 text-sm m-auto rounded-2xl relative mt-6">
        <div className="flex justify-between py-2 border-b border-borderYellow items-center">
          <span className="font-bold px-4 py-3 text-textBlue1">
            {selectedInstitution ? formData.name : 'Create Institution'}
          </span>
          <div className="flex gap-x-8 items-center relative justify-end">
            <img src="assets/img/Close.svg" className="h-3 cursor-pointer mr-4" alt="" onClick={handleClose} />
          </div>
        </div>

        <div className=" w-full h-1/4 px-4 pb-6">
          <form className="grid grid-cols-2 gap-x-6 px-4 pb-2" onSubmit={handleSubmit}>
            <div className="my-2 relative font-semibold w-full">
              <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                Name
              </label>
              <input
                type="text"
                name="name"
                // placeholder="All"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
              />
            </div>

            <div className="my-2 w-full relative font-semibold ">
              <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                Slug
              </label>
              <input
                type="text"
                name="slug"
                // placeholder="slug"
                value={formData.slug}
                className="w-full rounded-2xl text-xs border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                onChange={handleInputChange}
              />
            </div>

            <div className="my-2 relative font-semibold w-full">
              <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                Address
              </label>
              <input
                type="text"
                name="address"
                // placeholder="All"
                value={formData.address}
                onChange={handleInputChange}
                className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
              />
            </div>

            <div className="my-2 relative font-semibold w-full">
              <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                State
              </label>
              <select
                type="text"
                name="state"
                value={formData.state}
                onChange={handleInputChange}
                className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
              >
                <option>Select</option>
                {masterData?.state?.length && masterData?.state[0].stateList?.map((x) => <option key={x}>{x}</option>)}
              </select>
            </div>

            <div className="my-2 relative font-semibold w-full">
              <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                District
              </label>
              <select
                type="text"
                name="district"
                value={formData.district}
                onChange={handleInputChange}
                className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
              >
                <option>Select</option>
                {masterData?.district
                  ?.find((x) => x.key === formData.state)
                  ?.districtList?.map((x) => (
                    <option key={x}>{x}</option>
                  ))}
              </select>
            </div>

            <div className="my-2 w-full relative font-semibold ">
              <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                Institute Type
              </label>
              <select
                name="type"
                value={formData.type}
                className="w-full rounded-2xl text-xs border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                onChange={handleTypeChange}
              >
                <option value="">Select</option>
                {masterData?.instituteType?.map((x) => (
                  <option key={x}>{x}</option>
                ))}
              </select>
            </div>
            { isUniversity ?
            <div className="my-2 relative font-semibold w-full">
              <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                University
              </label>
              <select
                type="text"
                name="university"
                value={formData.university}
                onChange={handleInputChange}
                className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
              >
                <option key={""} value={""} >Select</option>
                {universityData?.map((c) => (
                  <option key={c._id} value={c._id} >{c.name}</option>
                ))}
              </select>
            </div>
            :<div className="my-2 relative font-semibold w-full">
              <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                Board
              </label>
              <select
                type="text"
                name="board"
                value={formData.board}
                onChange={handleInputChange}
                className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
              >
                <option>Select</option>
                {masterData?.board?.map((x) => (
                  <option key={x.key}>{x.name}</option>
                ))}
              </select>
            </div>  }

            <div className="my-2 relative font-semibold w-full">
              <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                Phone
              </label>
              <input
                type="tel"
                name="phone"
                // placeholder="All"
                value={formData.phone}
                onChange={handleInputChange}
                className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
              />
            </div> 
            <div className="my-2 w-full relative font-semibold ">
              <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                Transport Fee
              </label>
              <select
                type="text"
                name="transportFee"
                // placeholder="transportFee"
                value={formData.transportFee}
                className="w-full rounded-2xl text-xs border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                onChange={handleInputChange}
              >
                <option value="">Select</option>
                {masterData?.transportFee?.map((x) => (
                  <option key={x}>{x}</option>
                ))}
              </select>
            </div>

            <div className="my-2 w-full relative font-semibold ">
              <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                Transport Fee
              </label>
              <select
                type="text"
                name="financeCycle"
                // placeholder="transportFee"
                value={formData.financeCycle}
                className="w-full rounded-2xl text-xs border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                onChange={handleInputChange}
              >
                <option value={1}>Monthly</option>
                <option value={3}>Quarterly</option>
                <option value={6}>Half Yearly</option>
                <option value={12}>Yearly</option>
              </select>
            </div>

            <div className="my-2 w-full relative font-semibold ">
              <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                Url
              </label>
              <input
                type="text"
                name="url"
                // placeholder="url"
                value={formData.url}
                className="w-full rounded-2xl text-xs border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                onChange={handleInputChange}
              />
            </div>

            <div className="my-2 w-full relative font-semibold col-span-2">
              <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                About
              </label>
              <textarea
                type="text"
                name="about"
                // placeholder="about"
                rows="4"
                value={formData.about}
                cols="50"
                className="w-full rounded-2xl text-xs border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                onChange={handleInputChange}
              />
            </div>
            <div className="my-2 relative font-semibold w-full">
              <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                Logo
              </label>
              <input
                hidden
                ref={logoRef}
                type="file"
                onChange={(e) => setFormData({ ...formData, logo: e.target.files[0] })}
              />

              <div className="relative flex">
                <input
                  // required
                  type="text"
                  disabled
                  value={formData.logo?.name}
                  className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                />
                <button
                  className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center h-9 mt-2 absolute right-0"
                  onClick={(e) => {
                    e.preventDefault();
                    logoRef.current.click();
                  }}
                >
                  Upload
                </button>
              </div>

              {selectedInstitution && formData.logo && (
                <a className="text-blue-700 underline" href={formData.logo.link} download>
                  <p className="text-primary underline">{formData.logo.name}</p>
                </a>
              )}
            </div>

            <div className="my-2 relative font-semibold w-full">
              <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                Cover
              </label>
              <input
                hidden
                ref={coverRef}
                type="file"
                onChange={(e) => setFormData({ ...formData, cover: e.target.files[0] })}
              />

              <div className="relative flex">
                <input
                  // required
                  type="text"
                  disabled
                  value={formData.cover?.name}
                  className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                />
                <button
                  className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center h-9 mt-2 absolute right-0"
                  onClick={(e) => {
                    e.preventDefault();
                    coverRef.current.click();
                  }}
                >
                  Upload
                </button>
              </div>

              {selectedInstitution && formData.cover && (
                <a className="text-blue-700 underline" href={formData.cover.link} download>
                  <p className="text-primary underline">{formData.cover.name}</p>
                </a>
              )}
            </div>

            <div className="w-full mt-4 flex justify-end col-span-2 gap-x-4">
              {isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
              ) : (
                <>
                  {!selectedInstitution && (
                    <button className="border border-tableRowText text-textDark px-12 py-2 text-xs rounded-3xl font-medium mx-1 focus:outline-none flex justify-center text-center items-center">
                      Reset
                    </button>
                  )}
                  <button
                    type="submit"
                    className="border px-12 py-2 text-xs rounded-3xl bg-primary border-primary font-medium text-white focus:outline-none flex justify-center text-center items-center"
                  >
                    {selectedInstitution ? 'Edit' : 'Create'}
                  </button>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateInstitution;

import React from 'react';
import moment from 'moment';
// import DropDown from '../UI/DropDown';

function IncidetReported({ selectIncident, data, title }) {
  console.log(data);
  return (
    <div className="w-full bg-white rounded-xl h-auto mb-4">
      <div className="items-center px-6 py-3 border-b border-borderYellow">
        <div className="flex justify-between items-center">
          <div className="font-bold w-1/3">
            <p>{title}</p>
          </div>
          <div className="flex gap-x-4 w-2/3 justify-end">
            {/* <div className="w-1/4 border border-tableRowText px-3 py-2 rounded-3xl text-xs  focus:outline-none flex">
              <img src="assets/img/Search.svg" className=" w-4 mr-2 " alt="" />
              <input
                type="text"
                placeholder="Search staff"
                autoComplete="off"
                // value="Search staff"
                className="text-left w-full  bg-transparent text-textLight  focus:outline-none"
                onChange={(x) => {
                  setSearchName(x.target.value);
                }}
                onKeyDown={(x) => {
                  if (x.which === 13) {
                    setSearch((prev) => !prev);
                  }
                }}
              />
            </div>

            <DropDown
              data={['All', 'Pre - Primary', 'Primary', 'Middle', 'Secondary', 'Senior Secondary']}
              width={'w-1/4'}
              title={'Sort By'}
              handleItemClick={() => {}}
            /> */}
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4 px-6">
        <div className="w-full py-4">
          <div className="bg-white m-auto border rounded-2xl border-borderYellow">
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-left px-4 text-xs font-medium">
                <div className="w-2/12 border-r-2 py-2 px-2">
                  {title === 'InActive Incidents' ? 'Updated Date' : 'Date'}
                </div>
                <div className="w-3/12 border-r-2 py-2 px-2">Incident</div>
                <div className="w-2/12 border-r-2 py-2 px-2">Reported By</div>
                <div className="w-3/12 border-r-2 py-2 px-2">Action Taken</div>
                <div className="w-2/12 py-2 px-2"></div>
              </div>
            </div>
            <div className="showScroll overflow-auto" style={{ maxHeight: '55vh' }}>
              {data &&
                Array.isArray(data.data) &&
                data.data.map((x, idx) => {
                  return (
                    <div className="w-full">
                      <div
                        className={`justify-center flex w-full relative text-left py-2 px-4 text-xs font-medium items-center ${
                          idx !== data.data.length - 1 && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-2/12 px-2 text-tableRowText">
                          {title === 'InActive Incidents'
                            ? moment(x.updatedAt).format('ll')
                            : moment(x.date).format('ll')}
                        </div>
                        <div className={`w-3/12 px-2 text-textDark `}>{x?.incident}</div>
                        <div className={`w-2/12 px-2 text-textDark `}>{x?.userId?.full_name}</div>
                        <div className={`w-3/12 px-2 text-textDark `}>{x.action || '--'}</div>
                        <div className={`w-2/12 px-2 py-0.5`}>
                          <button
                            className="w-11/12 border py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center  border-primary text-primary"
                            onClick={() => selectIncident(x)}
                          >
                            {title === 'InActive Incidents' ? 'View' : 'View & Edit'}
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IncidetReported;

import Cookies from 'js-cookie';

const x = Cookies.get('auth');

export const initialState = {
  token: x ? JSON.parse(x) : null,
  user: null,
  institutes: [],
  masterData: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'TOKEN':
      return {
        ...state,
        token: action.token,
      };

    case 'USER':
      return {
        ...state,
        user: action.user,
      };

    case 'INSTITUTES':
      return {
        ...state,
        institutes: action.institutes,
      };

    case 'MASTER_DATA':
      return {
        ...state,
        masterData: action.masterData,
      };
    case 'UNIVERSITY_DATA':
      return {
        ...state,
        universityData: action.universityData,
      };

    default:
      return state;
  }
};

export default reducer;

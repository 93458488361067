import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useApi from '../../api/useApi';
import { UPDATE_USER } from '../../api/urls';
import DropDown from '../../components/UI/DropDownCustom';

const AddStaff = ({ handleClose, staffRole, selectedStaff, refreshList, selectedInstitution }) => {
  const [step, setStep] = useState(0);
  const formDataModel = {
    email_id: '',
    full_name: '',
    gender: '',
    dateOfBirth: '',
    maritalStatus: '',
    // aadhar: '',
    roleType: staffRole === 'teaching' ? 'te' : 'nt',
    role: staffRole,
  };

  const addressModel = {
    hno: '',
    society: '',
    district: '',
    city: '',
    state: '',
    pincode: '',
  };

  const experienceModel = {
    title: '',
    type: '',
    companyName: '',
    location: '',
    startDate: '',
    endDate: '',
  };

  const educationModel = {
    university: '',
    degree: '',
    fieldOfStudy: '',
    grade: '',
    startDate: '',
    endDate: '',
  };

  const jobProfileModel = {
    title: '',
    supervisor: '',
    email: '',
    contact: '',
    startDate: '',
    salary: '',
  };

  const bankAccountModel = {
    bank: '',
    accountNo: '',
    ifsc: '',
  };

  const [formData, setFormData] = useState(formDataModel);
  const [addressState, setAddressState] = useState(addressModel);
  const [caddressState, setcAddressState] = useState(addressModel);
  const [experienceState, setexperienceState] = useState([experienceModel]);
  const [educationState, setEducationState] = useState([educationModel]);
  const [jobProfileState, setJobProfileState] = useState([jobProfileModel]);
  const [bankState, setBankState] = useState(bankAccountModel);
  const [profileImg, setProfileImg] = useState();
  const [image, setImage] = useState('/assets/img/user_placeholder.svg');

  const [experienceList, setExperienceList] = useState([0]);
  const [educationList, setEducationList] = useState([0]);
  const [jobProfileList, setJobProfileList] = useState([0]);

  const imgRef = useRef();

  useEffect(() => {
    if (profileImg) {
      setImage(URL.createObjectURL(profileImg));
    }
  }, [profileImg]);

  useEffect(() => {
    if (selectedStaff) {
      setFormData(selectedStaff);
      setAddressState(selectedStaff.address);
      setcAddressState(selectedStaff.caddress);
      setexperienceState(selectedStaff.experience);
      setEducationState(selectedStaff.education);
      setJobProfileState(selectedStaff.jobProfile);
      setBankState(selectedStaff.bankDetail);

      setEducationList(selectedStaff.education.map((x, idx) => idx));
      setExperienceList(selectedStaff.experience.map((x, idx) => idx));
      setJobProfileList(selectedStaff.jobProfile.map((x, idx) => idx));
      if (selectedStaff.fileArray.length > 0 && selectedStaff.fileArray[0].profileImg.location)
        setImage(selectedStaff.fileArray[0].profileImg.location);
    }
  }, [selectedStaff]);
  console.log(selectedStaff.dateOfBirth);
  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleAddressInputChange = (e) => setAddressState({ ...addressState, [e.target.name]: e.target.value });

  const handlecAddressInputChange = (e) => setcAddressState({ ...caddressState, [e.target.name]: e.target.value });

  const handleExperienceChange = (e, index) => {
    const x = experienceState;
    const y = {
      ...experienceState[index],
      [e.target.name]: e.target.value,
    };
    x[index] = y;
    setexperienceState([...x]);
  };

  const handleEducationChange = (e, index) => {
    const x = educationState;
    const y = {
      ...educationState[index],
      [e.target.name]: e.target.value,
    };
    x[index] = y;
    setEducationState([...x]);
  };

  const handleJobProfileChange = (e, index) => {
    const x = jobProfileState;
    const y = {
      ...jobProfileState[index],
      [e.target.name]: e.target.value,
    };
    x[index] = y;
    setJobProfileState([...x]);
  };

  const handlecBankInputChange = (e) => setBankState({ ...bankState, [e.target.name]: e.target.value });

  const [{ isLoading, isError, data, status }, createStaff] = useApi();

  const handleSubmit = (e) => {
    e.preventDefault();

    const body = new FormData();
    body.append('instituteId', selectedInstitution);
    body.append('address', JSON.stringify(addressState));
    body.append('caddress', JSON.stringify(caddressState));
    body.append('experience', JSON.stringify(experienceState));
    body.append('education', JSON.stringify(educationState));
    body.append('jobProfile', JSON.stringify(jobProfileState));
    body.append('bankDetail', JSON.stringify(bankState));
    body.append('email_id', formData.email_id);
    body.append('full_name', formData.full_name);
    body.append('gender', formData.gender);
    body.append('dateOfBirth', formData.dateOfBirth);

    // body.append('aadhar', formData.aadhar);
    body.append('maritalStatus', formData.maritalStatus);
    body.append('roleType', formData.roleType);
    body.append('role', staffRole);
    body.append('profileImg', profileImg);
    body.append('username', selectedStaff.username);
    // const body = {
    //   ...formData,
    //   address: addressState,
    //   caddress: caddressState,
    //   experience: experienceState,
    //   education: educationState,
    //   jobProfile: jobProfileState,
    //   bankDetail: bankState,
    // };
    // console.log(body);
    createStaff(UPDATE_USER(selectedStaff._id), 'PUT', body);
  };

  useEffect(() => {
    if (!isLoading && !isError && status) {
      setFormData(formDataModel);
      refreshList();
      handleClose();
    }
  }, [isLoading, isError, data, status]);

  // const getDateFormat = (x) => {
  //   console.log('x', x);
  //   if (!x) return null;
  //   const y = new Date(x);
  //   console.log(`${y.getDate()}-${y.getMonth() + 1}-${y.getFullYear()}`);
  //   return `${y.getDate()}-${y.getMonth() + 1}-${y.getFullYear()}`;
  // };

  return (
    <div
      className="flex h-auto min-h-screen pb-10 w-full absolute left-0 top-0 z-30"
      style={{
        background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))',
      }}
    >
      <div className="relative w-4/5 pb-10 mx-auto h-full overflow-auto mt-16 bg-white rounded-2xl">
        <div className="text-sm block relative border-b border-borderYellow">
          <div className="flex justify-between items-center py-5">
            <div className="font-bold px-8">
              <p>{selectedStaff ? `${formData.full_name} (ROLEDin ID - ${formData.username}))` : 'Add Staff'}</p>
            </div>
            <div className="flex px-8">
              <img src="assets/img/Close.svg" className=" h-3 cursor-pointer" alt="" onClick={handleClose} />
            </div>
          </div>
        </div>

        <div className="py-3 px-8 relative grid grid-cols-6 gap-x-2 border-b border-borderYellow">
          <button
            onClick={() => setStep(0)}
            className={`py-2 w-full rounded-full text-xs ${
              step === 0 ? 'bg-primary text-white' : 'bg-white text-black'
            }`}
          >
            Personal Information
          </button>
          <button
            onClick={() => setStep(1)}
            className={`py-2 w-full rounded-full text-xs ${
              step === 1 ? 'bg-primary text-white' : 'bg-white text-black'
            }`}
          >
            Address
          </button>
          <button
            onClick={() => setStep(2)}
            className={`py-2 w-full rounded-full text-xs ${
              step === 2 ? 'bg-primary text-white' : 'bg-white text-black'
            }`}
          >
            Experience
          </button>
          <button
            onClick={() => setStep(3)}
            className={`py-2 w-full rounded-full text-xs ${
              step === 3 ? 'bg-primary text-white' : 'bg-white text-black'
            }`}
          >
            Education
          </button>
          <button
            onClick={() => setStep(4)}
            className={`py-2 w-full rounded-full text-xs ${
              step === 4 ? 'bg-primary text-white' : 'bg-white text-black'
            }`}
          >
            Job Profile
          </button>
          <button
            onClick={() => setStep(5)}
            className={`py-2 w-full rounded-full text-xs ${
              step === 5 ? 'bg-primary text-white' : 'bg-white text-black'
            }`}
          >
            Bank Account
          </button>
        </div>

        {step === 0 && (
          <div className="px-8">
            <div className="items-center py-4 ">
              <p className=" font-medium ">Staff Information</p>
            </div>

            <div className="flex w-full">
              <div>
                <div className="profileLogo  bg-gray-500 w-56 h-56 relative">
                  {image && <img src={image} alt=" " className="h-full w-full profileLogo" />}
                  <input hidden ref={imgRef} type="file" onChange={(e) => setProfileImg(e.target.files[0])} />
                  <img
                    src="assets/img/camera.png"
                    className="w-10 h-10 absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-1/2"
                    onClick={(e) => {
                      e.preventDefault();
                      imgRef.current.click();
                    }}
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-x-4 pb-4 w-full pl-12">
                <div className="my-2 w-full relative font-semibold ">
                  <label htmlFor="category" className="text-textDark font-medium text-sm">
                    Full Name
                  </label>
                  <input
                    type="text"
                    // placeholder="Eg-Rakesh Ahirwar"
                    autoComplete="off"
                    value={formData.full_name}
                    name="full_name"
                    onChange={handleInputChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold ">
                  <label htmlFor="budget" className="text-textDark font-medium text-sm">
                    Age
                  </label>
                  <input
                    type="text"
                    // placeholder="Eg-32 Years"
                    value={formData.age}
                    name="age"
                    onChange={handleInputChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>
                <div className="my-2 w-full relative font-semibold ">
                  <label htmlFor="budget" className="text-textDark font-medium text-sm">
                    Gender
                  </label>
                  <DropDown
                    data={['Male', 'Female']}
                    handleItemClick={(x) => {
                      x && handleInputChange({ target: { value: x, name: 'gender' } });
                    }}
                    title={selectedStaff?.gender ? selectedStaff.gender : 'Select'}
                    width={'w-full'}
                    otherClasses={'border-borderLight mt-2 text-textDark'}
                  />
                </div>

                <div className="my-2 w-full relative font-semibold ">
                  <label htmlFor="budget" className="text-textDark font-medium text-sm">
                    Date of Birth
                  </label>
                  {/* {selectedStaff ? (
                    <p className="w-full rounded-3xl border font-normal border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none cur">
                      {moment(formData.dateOfBirth).format('d MMMM YYYY')}
                    </p>
                  ) : ( */}
                  <DatePicker
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none cur"
                    selected={formData.dateOfBirth && new Date(formData.dateOfBirth)}
                    onChange={(date) => setFormData({ ...formData, dateOfBirth: date })}
                    dateFormat={'dd-MM-yyyy'}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                  {/* )} */}
                </div>
                {/* <div className="my-2 w-full relative font-semibold ">
                  <label htmlFor="budget" className="text-textDark font-medium text-sm">
                    AADHAR
                  </label>
                  <input
                    type="text"
                    // placeholder="Eg-19 may-1992"
                    value={formData.aadhar}
                    name="aadhar"
                    onChange={handleInputChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div> */}
                <div className="my-2 w-full relative font-semibold ">
                  <label htmlFor="budget" className="text-textDark font-medium text-sm">
                    Marital Status
                  </label>
                  <DropDown
                    data={['Single', 'Married']}
                    handleItemClick={(x) => {
                      x && handleInputChange({ target: { value: x, name: 'maritalStatus' } });
                    }}
                    title={selectedStaff?.maritalStatus || 'Select'}
                    width={'w-full'}
                    otherClasses={'border-borderLight mt-2 text-textDark'}
                  />
                </div>
              </div>
            </div>

            <div className="flex gap-x-4 justify-end">
              {/* <button
                className="w-40 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                onClick={() => {}}
              >
                Save
              </button> */}
              <button
                className="w-40 border px-8 py-2 rounded-3xl text-xs text-white bg-primary focus:outline-none flex justify-center items-center"
                onClick={() => {
                  setStep(1);
                }}
              >
                Next
              </button>
            </div>
          </div>
        )}

        {step === 1 && (
          <div>
            <p className=" font-bold text-black pt-4 px-8">Permanent Address</p>
            <div className="grid grid-cols-2 gap-x-4 pb-4 w-full border-b border-borderYellow px-8">
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="category" className="text-textDark font-medium text-sm">
                  Flat No. / House No.
                </label>
                <input
                  type="text"
                  // placeholder="Eg-198"
                  autoComplete="off"
                  value={addressState.hno}
                  name="hno"
                  onChange={handleAddressInputChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Colony / Society
                </label>
                <input
                  type="text"
                  value={addressState.society}
                  name="society"
                  onChange={handleAddressInputChange}
                  // placeholder="Eg-Amar Colony"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  District
                </label>
                <input
                  type="text"
                  value={addressState.district}
                  name="district"
                  onChange={handleAddressInputChange}
                  // placeholder="Eg-Jalandhar"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  City
                </label>
                <input
                  type="text"
                  value={addressState.city}
                  name="city"
                  onChange={handleAddressInputChange}
                  // placeholder="Eg-Jalandhar"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  State
                </label>
                <input
                  type="text"
                  value={addressState.state}
                  name="state"
                  onChange={handleAddressInputChange}
                  // placeholder="Eg-Punjab"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Pin Code
                </label>
                <input
                  type="text"
                  value={addressState.pincode}
                  name="pincode"
                  onChange={handleAddressInputChange}
                  // placeholder="Eg-141401"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
            </div>

            <p className=" font-bold text-black pt-4 px-8">Correspondance Address</p>
            <div className="grid grid-cols-2 gap-x-4 pb-4 w-full px-8">
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="category" className="text-textDark font-medium text-sm">
                  Flat No. / House No.
                </label>
                <input
                  type="text"
                  // placeholder="Eg-198"
                  value={caddressState.hno}
                  name="hno"
                  onChange={handlecAddressInputChange}
                  autoComplete="off"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Colony / Society
                </label>
                <input
                  type="text"
                  value={caddressState.society}
                  name="society"
                  onChange={handlecAddressInputChange}
                  // placeholder="Eg-Amar Colony"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  District
                </label>
                <input
                  type="text"
                  // placeholder="Eg-Jalandhar"
                  value={caddressState.district}
                  name="district"
                  onChange={handlecAddressInputChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  City
                </label>
                <input
                  type="text"
                  value={caddressState.city}
                  name="city"
                  onChange={handlecAddressInputChange}
                  // placeholder="Eg-Jalandhar"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  State
                </label>
                <input
                  type="text"
                  value={caddressState.state}
                  name="state"
                  onChange={handlecAddressInputChange}
                  // placeholder="Eg-Punjab"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Pin Code
                </label>
                <input
                  type="text"
                  value={caddressState.pincode}
                  name="pincode"
                  onChange={handlecAddressInputChange}
                  // placeholder="Eg-141401"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
            </div>

            <div className="flex gap-x-4 justify-end px-8">
              {/* <button
                className="w-40 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                onClick={() => {}}
              >
                Save
              </button> */}
              <button
                className="w-40 border px-8 py-2 rounded-3xl text-xs text-white bg-primary focus:outline-none flex justify-center items-center"
                onClick={() => {
                  setStep(2);
                }}
              >
                Next
              </button>
            </div>
          </div>
        )}

        {step === 2 && (
          <>
            {experienceList.map((x) => (
              <div>
                <p className=" font-bold text-black pt-4 px-8">Experience 1</p>
                <div className="grid grid-cols-2 gap-x-4 pb-4 w-full px-8">
                  <div className="my-2 w-full relative font-semibold">
                    <label htmlFor="category" className="text-textDark font-medium text-sm">
                      Title
                    </label>
                    <input
                      type="text"
                      // placeholder="Eg-198"
                      autoComplete="off"
                      value={experienceState[x]?.title}
                      name="title"
                      onChange={(e) => handleExperienceChange(e, x)}
                      className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                    />
                  </div>

                  <div className="my-2 w-full relative font-semibold">
                    <label htmlFor="budget" className="text-textDark font-medium text-sm">
                      Employment Type
                    </label>
                    <DropDown
                      data={['Full Time', 'Part Time', 'Contract']}
                      handleItemClick={(x) => {
                        handleExperienceChange({ target: { value: x, name: 'type' } }, x);
                      }}
                      title={(selectedStaff?.experience.length > 0 && selectedStaff?.experience[x]?.type) || 'Select'}
                      width={'w-full'}
                      otherClasses={'border-borderLight mt-2 text-textDark'}
                    />
                  </div>
                  <div className="my-2 w-full relative font-semibold">
                    <label htmlFor="budget" className="text-textDark font-medium text-sm">
                      Company Name
                    </label>
                    <input
                      type="text"
                      // placeholder="Eg-Jalandhar"
                      value={experienceState[x].companyName}
                      name="companyName"
                      onChange={(e) => handleExperienceChange(e, x)}
                      className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                    />
                  </div>

                  <div className="my-2 w-full relative font-semibold">
                    <label htmlFor="budget" className="text-textDark font-medium text-sm">
                      Location
                    </label>
                    <input
                      type="text"
                      // placeholder="Eg-Jalandhar"
                      value={experienceState[x].location}
                      name="location"
                      onChange={(e) => handleExperienceChange(e, x)}
                      className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                    />
                  </div>

                  <div className="my-2 w-full relative font-semibold">
                    <label htmlFor="budget" className="text-textDark font-medium text-sm">
                      Start Date
                    </label>
                    {/* {selectedStaff ? (
                      <p className="w-full rounded-3xl border font-normal border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none cur">
                        {moment(formData.dateOfBirth).format('d MMMM YYYY')}
                      </p>
                    ) : ( */}
                    <DatePicker
                      className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none cur"
                      selected={experienceState[x].startDate && new Date(experienceState[x].startDate)}
                      onChange={(date) => handleExperienceChange({ target: { name: 'startDate', value: date } }, x)}
                      dateFormat={'dd-MM-yyyy'}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                    {/* )} */}
                  </div>

                  <div className="my-2 w-full relative font-semibold">
                    <label htmlFor="budget" className="text-textDark font-medium text-sm">
                      End Date
                    </label>
                    {/* {selectedStaff ? (
                      <p className="w-full rounded-3xl border font-normal border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none cur">
                        {moment(formData.dateOfBirth).format('d MMMM YYYY')}
                      </p>
                    ) : ( */}
                    <DatePicker
                      className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none cur"
                      selected={experienceState[x].endDate && new Date(experienceState[x].endDate)}
                      onChange={(date) => handleExperienceChange({ target: { name: 'endDate', value: date } }, x)}
                      dateFormat={'dd-MM-yyyy'}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                    {/* )} */}
                  </div>
                </div>
              </div>
            ))}

            <div className="flex gap-x-4 justify-end px-8">
              {/* {!selectedStaff && ( */}
              <button
                className="w-40 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                onClick={(e) => {
                  e.preventDefault();
                  setExperienceList([...experienceList, experienceList.length]);
                  setexperienceState([...experienceState, experienceModel]);
                }}
              >
                Add Section
              </button>
              {/* )} */}
              {/* <button
                className="w-40 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                onClick={() => {}}
              >
                Save
              </button> */}
              <button
                className="w-40 border px-8 py-2 rounded-3xl text-xs text-white bg-primary focus:outline-none flex justify-center items-center"
                onClick={() => {
                  setStep(3);
                }}
              >
                Next
              </button>
            </div>
          </>
        )}

        {step === 3 && (
          <>
            {educationList.map((x) => (
              <div>
                <p className=" font-bold text-black pt-4 px-8">Education 1</p>
                <div className="grid grid-cols-2 gap-x-4 pb-4 w-full px-8">
                  <div className="my-2 w-full relative font-semibold">
                    <label htmlFor="category" className="text-textDark font-medium text-sm">
                      University
                    </label>
                    <input
                      type="text"
                      // placeholder="Eg-198"
                      autoComplete="off"
                      value={educationState[x].university}
                      name="university"
                      onChange={(e) => handleEducationChange(e, x)}
                      className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                    />
                  </div>

                  <div className="my-2 w-full relative font-semibold">
                    <label htmlFor="budget" className="text-textDark font-medium text-sm">
                      Degree
                    </label>
                    <input
                      type="text"
                      // placeholder="Full Time / Part Time / Contract"
                      value={educationState[x].degree}
                      name="degree"
                      onChange={(e) => handleEducationChange(e, x)}
                      className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                    />
                  </div>
                  <div className="my-2 w-full relative font-semibold">
                    <label htmlFor="budget" className="text-textDark font-medium text-sm">
                      Field of Study
                    </label>
                    <input
                      type="text"
                      // placeholder="Eg-Jalandhar"
                      value={educationState[x].fieldOfStudy}
                      name="fieldOfStudy"
                      onChange={(e) => handleEducationChange(e, x)}
                      className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                    />
                  </div>

                  <div className="my-2 w-full relative font-semibold">
                    <label htmlFor="budget" className="text-textDark font-medium text-sm">
                      Grade
                    </label>
                    <input
                      type="text"
                      value={educationState[x].grade}
                      name="grade"
                      onChange={(e) => handleEducationChange(e, x)}
                      // placeholder="Eg-Jalandhar"
                      className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                    />
                  </div>

                  <div className="my-2 w-full relative font-semibold">
                    <label htmlFor="budget" className="text-textDark font-medium text-sm">
                      Start Date
                    </label>
                    {/* {selectedStaff ? (
                      <p className="w-full rounded-3xl border font-normal border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none cur">
                        {moment(formData.dateOfBirth).format('d MMMM YYYY')}
                      </p>
                    ) : ( */}
                    <DatePicker
                      className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none cur"
                      selected={educationState[x].startDate && new Date(educationState[x].startDate)}
                      onChange={(date) => handleEducationChange({ target: { name: 'startDate', value: date } }, x)}
                      dateFormat={'dd-MM-yyyy'}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                    {/* )} */}
                  </div>

                  <div className="my-2 w-full relative font-semibold">
                    <label htmlFor="budget" className="text-textDark font-medium text-sm">
                      End Date
                    </label>
                    {/* {selectedStaff ? (
                      <p className="w-full rounded-3xl border font-normal border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none cur">
                        {moment(formData.dateOfBirth).format('d MMMM YYYY')}
                      </p>
                    ) : ( */}
                    <DatePicker
                      className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none cur"
                      selected={educationState[x].endDate && new Date(educationState[x].endDate)}
                      onChange={(date) => handleEducationChange({ target: { name: 'endDate', value: date } }, x)}
                      dateFormat={'dd-MM-yyyy'}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                    {/* )} */}
                  </div>
                </div>
              </div>
            ))}
            <div className="flex gap-x-4 justify-end px-8">
              {/* {!selectedStaff && ( */}
              <button
                className="w-40 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                onClick={(e) => {
                  e.preventDefault();
                  setEducationList([...educationList, educationList.length]);
                  setEducationState([...educationState, educationModel]);
                }}
              >
                Add Section
              </button>
              {/* )} */}
              {/* <button
                className="w-40 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                onClick={() => {}}
              >
                Save
              </button> */}
              <button
                className="w-40 border px-8 py-2 rounded-3xl text-xs text-white bg-primary focus:outline-none flex justify-center items-center"
                onClick={() => {
                  setStep(4);
                }}
              >
                Next
              </button>
            </div>
          </>
        )}

        {step === 4 && (
          <>
            {jobProfileList.map((x) => (
              <div>
                <p className=" font-bold text-black pt-4 px-8">Job Profile</p>
                <div className="grid grid-cols-2 gap-x-4 pb-4 w-full px-8">
                  <div className="my-2 w-full relative font-semibold">
                    <label htmlFor="category" className="text-textDark font-medium text-sm">
                      Title
                    </label>
                    <input
                      type="text"
                      // placeholder="Eg-198"
                      autoComplete="off"
                      value={jobProfileState[x].title}
                      name="title"
                      onChange={(e) => handleJobProfileChange(e, x)}
                      className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                    />
                  </div>

                  <div className="my-2 w-full relative font-semibold">
                    <label htmlFor="budget" className="text-textDark font-medium text-sm">
                      Supervisor
                    </label>
                    <input
                      type="text"
                      // placeholder="Full Time / Part Time / Contract"
                      value={jobProfileState[x].supervisor}
                      name="supervisor"
                      onChange={(e) => handleJobProfileChange(e, x)}
                      className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                    />
                  </div>
                  <div className="my-2 w-full relative font-semibold">
                    <label htmlFor="budget" className="text-textDark font-medium text-sm">
                      Email
                    </label>
                    <input
                      type="text"
                      value={jobProfileState[x].email}
                      name="email"
                      onChange={(e) => handleJobProfileChange(e, x)}
                      // placeholder="Eg-Jalandhar"
                      className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                    />
                  </div>

                  <div className="my-2 w-full relative font-semibold">
                    <label htmlFor="budget" className="text-textDark font-medium text-sm">
                      Contact Number
                    </label>
                    <input
                      value={jobProfileState[x].contact}
                      name="contact"
                      onChange={(e) => handleJobProfileChange(e, x)}
                      type="text"
                      // placeholder="Eg-Jalandhar"
                      className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                    />
                  </div>

                  <div className="my-2 w-full relative font-semibold">
                    <label htmlFor="budget" className="text-textDark font-medium text-sm">
                      Start Date
                    </label>
                    {/* {selectedStaff ? (
                      <p className="w-full rounded-3xl border font-normal border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none cur">
                        {moment(formData.dateOfBirth).format('d MMMM YYYY')}
                      </p>
                    ) : ( */}
                    <DatePicker
                      className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none cur"
                      selected={jobProfileState[x].startDate && new Date(jobProfileState[x].startDate)}
                      onChange={(date) => handleJobProfileChange({ target: { name: 'startDate', value: date } }, x)}
                      dateFormat={'dd-MM-yyyy'}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                    {/* )} */}
                  </div>

                  <div className="my-2 w-full relative font-semibold">
                    <label htmlFor="budget" className="text-textDark font-medium text-sm">
                      Salary
                    </label>
                    <input
                      type="text"
                      value={jobProfileState[x].salary}
                      name="salary"
                      onChange={(e) => handleJobProfileChange(e, x)}
                      // placeholder="Eg-Jalandhar"
                      className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                    />
                  </div>
                </div>
              </div>
            ))}
            <div className="flex gap-x-4 justify-end px-8">
              {/* {!selectedStaff && ( */}
              <button
                className="w-40 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                onClick={(e) => {
                  e.preventDefault();
                  setJobProfileList([...jobProfileList, jobProfileList.length]);
                  setJobProfileState([...jobProfileState, jobProfileModel]);
                }}
              >
                Add Section
              </button>
              {/* )} */}
              {/* <button
                className="w-40 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                onClick={() => {}}
              >
                Save
              </button> */}
              <button
                className="w-40 border px-8 py-2 rounded-3xl text-xs text-white bg-primary focus:outline-none flex justify-center items-center"
                onClick={() => {
                  setStep(5);
                }}
              >
                Next
              </button>
            </div>
          </>
        )}

        {step === 5 && (
          <form autoComplete="off ">
            <p className=" font-bold text-black pt-4 px-8">Salary Account Details</p>
            <div className="grid grid-cols-2 gap-x-4 pb-4 w-full px-8">
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="category" className="text-textDark font-medium text-sm">
                  Bank
                </label>
                <input
                  type="text"
                  value={bankState?.bank}
                  name="bank"
                  onChange={handlecBankInputChange}
                  // placeholder="Eg. Kotak Mahindra Bank"
                  autoComplete="off"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Account Number
                </label>
                <input
                  type="text"
                  value={bankState?.accountNo}
                  name="accountNo"
                  onChange={handlecBankInputChange}
                  // placeholder="Eg. 258669585456878"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  IFSC
                </label>
                <input
                  type="text"
                  value={bankState?.ifsc}
                  name="ifsc"
                  onChange={handlecBankInputChange}
                  // placeholder="Eg. KKBK0000111"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
            </div>

            <div className="flex gap-x-4 justify-end px-8">
              {isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
              ) : (
                <button
                  className="w-40 border px-8 py-2 rounded-3xl text-xs text-white bg-primary focus:outline-none flex justify-center items-center"
                  onClick={handleSubmit}
                >
                  {selectedStaff ? 'Update' : 'Add Staff'}
                </button>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default AddStaff;

import React, { useState, useEffect } from 'react';
import useApi from '../../api/useApi';
import { POST_University_Events } from '../../api/urls';
import { useStateValue } from '../../ContextApi/StateProvider';
import moment from 'moment'; 

function Add({ onClose }) {
  const [{ masterData, universityData }, dispatch] = useStateValue();
  const [forUniversity, setUniversity] = useState("");
  const EmptyEventObj = { type: '1', name: '', desc: '', startdate: '', enddate: ''}
  const [events, setEvents] = useState([EmptyEventObj]);
  const [Api_Res , addEvents] = useApi();

  const handleSubmit = (e) => { console.log(" handle event ") ;
    e.preventDefault(); 
    let str = ''; 
    let correctDates = "" ;
    // console.log(" -- events.length ", events.length ) ;
    // events.forEach((x, idx) => 
    for (var idx = 0; idx < events.length; idx++ ) 
    {
      let x = events[idx]; // console.log(" -- 0 ", x ) ;
      if (!x?.type || x?.type == "" || !x?.name || x?.name == "" || !x?.desc || x?.desc == "" || !x?.startdate || x?.startdate == "" || !x?.enddate || x?.enddate == "" ) {
        str = str + `${idx + 1},`;
        // console.log(" -- 1 ", str ) ;
      } else if( moment(x?.enddate).isBefore(moment(x?.startdate)) ) {
        correctDates = correctDates + `${idx + 1},` ;
      } 
    }; //  console.log(" correctDates :  ",  correctDates  ) ;

    if (str || forUniversity == "" || correctDates != "" ) {
      if( str ){ str = "Please enter all fields for entry "+ str.slice(0, -1) + ".\n" ; }
      if( forUniversity == "" ){ str = "Please select University.\n" + str  ;  }
      if( correctDates != "" ){  str =  str + "Please enter correct End Date for entery " + correctDates.slice(0, -1) + ".\n" ;  }
      alert(str);
      return;
    }
    addEvents(POST_University_Events, 'POST', { universityID : forUniversity , eventsList: events });
  }
  useEffect(() => { console.log(" show res : ", Api_Res );
    if (!Api_Res.isLoading && !Api_Res.isError && Api_Res.status == 200) {
      onClose(true);
      alert('SUCCESS');
    }

    if (!Api_Res.isLoading && Api_Res.isError) {
      alert( Api_Res.data?.message || Api_Res.data?.msg || 'FAILED');
    }
  }, [ Api_Res ]);
  
  const addEventItem = (e) => {
    e.preventDefault();
    setEvents([ ...events , EmptyEventObj ]) ;
  };
  const handleInputChange = (e, index) => {
    const x = events;

    let inputValue = e.target.value || '';
    if (e.target.value.startsWith('-')) {
      inputValue = e.target.value.slice(1);
    }

    const y = {
      ...events[index],
      [e.target.name]: inputValue,
    };
    x[index] = y;
    setEvents([...x]);
  };

  const removeEventItem = (id) => {
      events.splice(id , 1);
      setEvents([...events]); 
  };
  // console.log("-- forUniversity : ", forUniversity ) ; 
  return (
    <div className="w-full bg-white rounded-xl h-auto mb-4">
      <div className="flex justify-between py-2 px-6 border-b border-borderYellow items-center">
        <span className="font-bold py-3 text-textBlue1">Add University Events</span>

        <div className="flex gap-x-8 items-center relative justify-end">
          <button
            className="border py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 bg-primary border-primary text-white w-32"
            onClick={() => {
              onClose(false);
            }}
          >
            Back
          </button>
        </div>
      </div>
      <div className=" w-full  px-4 pb-6">
        <form className="px-4 pb-2" onSubmit={handleSubmit}>
          <div>
              <h3 className="font-medium mt-4 mb-0 pb-0 text-lg flex justify-between items-center"> 
                <div className="my-2 relative font-semibold w-60">
                  <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                    Select University
                  </label>
                  <select
                    type="text"
                    name="university"
                    value={forUniversity}
                    onChange={(e, index) => { setUniversity(e.target.value); } }
                    className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                  >
                    <option key={""} value={""} >Select</option>
                    {universityData?.map((c) => (
                      <option key={c._id} value={c._id} >{c.name}</option>
                    ))}
                  </select>
                </div>
                <button
                  className="border w-32 py-2 text-xs rounded-3xl bg-white border-primary font-medium text-primary focus:outline-none flex justify-center text-center items-center"
                  onClick={addEventItem}
                >
                  Add More
                </button>
              </h3>
              {events.map((EachEvent, x ) => ( <div key={x} >
                <div className="grid grid-cols-3 gap-x-6 relative ">
                  <div className="my-2 relative font-semibold w-full">
                    <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                      Event Type
                    </label>
                    <select
                      type="text"
                      name="type"
                      value={EachEvent?.type}
                      onChange={(e) => handleInputChange(e, x)}
                      className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                    >
                      <option value={"1"} >Non-Working</option> 
                      <option value={"2"} >Working</option> 
                    </select>
                  </div>

                  <div className="my-2 relative font-semibold w-full">
                    <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={EachEvent?.name}
                      onChange={(e) => handleInputChange(e, x)}
                      className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                    />
                  </div> 

                  <div
                    className="rounded-full bg-borderLight absolute p-2 right-1 cursor-pointer top-10"
                    onClick={() => removeEventItem(x)}
                  >
                    <img src="assets/img/Close.svg" alt="Close" className="h-3" />
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-x-6 relative ">
                  <div className="my-2 w-full relative font-semibold">
                    <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                      Start Date
                    </label>
                    <input
                      type="date"
                      name="startdate"
                      value={EachEvent?.startdate}
                      onChange={(e) => handleInputChange(e, x)}
                      className="w-10/12 rounded-2xl text-xs border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                    />
                  </div>
                  <div className="my-2 w-full relative font-semibold">
                    <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                      End Date
                    </label>
                    <input
                      type="date"
                      name="enddate"
                      value={EachEvent?.enddate}
                      onChange={(e) => handleInputChange(e, x)}
                      className="w-10/12 rounded-2xl text-xs border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-x-6 relative border-b border-borderYellow pb-2">
                  <div className="my-2 w-full relative font-semibold col-span-2">
                    <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                      Description
                    </label>
                    <textarea
                      type="text"
                      name="desc" 
                      rows="4"
                      value={EachEvent?.desc}
                      onChange={(e) => handleInputChange(e, x)}
                      cols="50"
                      className="w-full rounded-2xl text-xs border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                      
                    />
                  </div>
                </div>
              </div>))}

              <div className="w-full mt-4 flex justify-end col-span-2 gap-x-4">
                {/* <button
                  className="border px-12 py-2 text-xs rounded-3xl bg-white border-borderLight font-medium text-textLight focus:outline-none flex justify-center text-center items-center"
                  onClick={(e) => {
                    e.preventDefault();
                    setStep(1);
                  }}
                >
                  Previous
                </button> */}
                { Api_Res.isLoading ? (
                  <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
                ) : (
                  <>
                    <button
                      type="submit"
                      className="border px-12 py-2 text-xs rounded-3xl bg-primary border-primary font-medium text-white focus:outline-none flex justify-center text-center items-center"
                    >
                      Save
                    </button>
                  </>
                )}
              </div>
            </div>
        </form>
      </div>
    </div>
  );
}

export default Add;

import React, { useState, useEffect } from 'react';
import { useStateValue } from '../ContextApi/StateProvider';
import { CHANGE_CLASSS_ATTENDANCE_TYPE } from '../api/urls';
import useApi from '../api/useApi';
import DropDown from '../components/UI/DropDownCustom';

const SubjectWiseAttendance = () => {
  const [{ institutes }, dispatch] = useStateValue();
  const [selectedInstitution, selectInstitution] = useState();
  const [classSectionlist, setclassSectionlist] = useState();
  const [statusRes, changeAttendanceStatus] = useApi();

  const changeStatus = (id, isActive) => {
    if (window.confirm(`Are you sure you want to ${isActive ? 'Deactivate' : 'Activate'} Subject Wise Attendance ?`))
      changeAttendanceStatus(CHANGE_CLASSS_ATTENDANCE_TYPE(selectedInstitution), 'POST', {
        institution: selectedInstitution, classID: id, status : isActive ? false : true 
      });
  };
  useEffect(() => { 
    if( selectedInstitution ){
      changeAttendanceStatus(CHANGE_CLASSS_ATTENDANCE_TYPE(selectedInstitution), 'POST', {
        institution: selectedInstitution, classID: null, status : null
      });
    }
  }, [selectedInstitution]);

  useEffect(() => {  //  console.log(" effect of -- statusRes") ;
    if (!statusRes.isLoading && !statusRes.isError && statusRes.status === 200 && statusRes?.data ) {
      // alert('Success'); 
      if( Array.isArray(statusRes?.data?.classList ) ){
        setclassSectionlist(statusRes?.data?.classList)
      }
    }
    if (!statusRes.isLoading && statusRes.isError) {
      alert('Error occurred.');
    }
  }, [statusRes]);

  return (
    <div className="w-4/5 pb-10">
      <div className="w-full bg-white rounded-xl h-auto mb-4">
        <div className="items-center px-6 py-3 border-b border-borderYellow">
          <div className="flex justify-between items-center">
            <div className="font-bold w-1/2">
              <p>Active / Deactive Subject Wise Attendance </p>
            </div>
            <div className="flex gap-x-4 w-2/3 justify-end">
              {/* 
              <button
                className={ `w-32 border py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 border-primary ${jobRes.isLoading ? "bg-white text-primary" : "bg-primary text-white"} `   }  
                onClick={() => handleRunJobs()} 
              >
                Fee Balance
              </button> */}
            </div>
          </div>
        </div>
        <div className="flex flex-wrap w-full h-1/4 px-6">
            <div className="w-full">
              <DropDown
                width={'w-1/3'}
                title={'Select Institution'}
                data={institutes}
                field={'name'}
                type={'obj'}
                handleItemClick={(x) => {
                  selectInstitution(x?._id);
                }}
                margin={'mt-4 mx-auto'}
              />
            </div>
        </div>
        <div className="flex flex-wrap w-full h-1/4 px-6">
          <div className="w-full py-4">
            <div className="bg-white m-auto border rounded-2xl border-borderYellow">
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-2/6 border-r-2 py-2 px-2">Class Name</div>
                  <div className="w-2/6 border-r-2 py-2 px-2">Subject Wise Attendance</div>
                  <div className="w-2/6 py-2 px-2">Action</div>
                </div>
              </div>
              <div className="customScrollBar" style={{ maxHeight: '65vh' }}>
                {Array.isArray(classSectionlist) &&
                  classSectionlist.map((x, idx) => {
                    return (
                      <div key={x._id} className="w-full">
                        <div
                          className={`justify-center flex w-full relative text-left py-4 px-4 text-xs font-medium items-center ${
                            classSectionlist.length - 1 !== idx && 'border-b border-borderYellow'
                          }`}
                        >
                          <div className="w-2/6 px-2 text-tableRowText ">{x?.className}</div>
                          <div className="w-2/6 px-2 text-tableRowText ">
                            {x?.isSubjectwise ? 'Active' : 'Not Active'}
                          </div>

                          <div className={`w-2/6 gap-x-3 flex`}>
                            <button
                              className={`px-3 border py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center  ${
                                x?.isSubjectwise
                                  ? 'bg-primary border-primary text-white'
                                  : 'bg-white border-primary text-primary'
                              }`}
                              onClick={() => changeStatus(x._id, x?.isSubjectwise)}
                            >
                              {x?.isSubjectwise ? 'Deactivate' : 'Activate'}
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubjectWiseAttendance ;

import React from 'react';
import DropDown from '../UI/DropDownCustom';
import Search from '../UI/Search';
import moment from 'moment';

function NewStudents({ institutes, openDetails, data, selectInstitution, setOpenUploadCsv, isLoading, handleDelete }) {
  return (
    <div className="w-full bg-white rounded-xl h-auto mb-4">
      <div className="items-center px-6 py-3 border-b border-borderYellow">
        <div className="flex justify-between items-center">
          <div className="font-bold w-1/3">
            <p>Students</p>
          </div>
          <div className="flex gap-x-4 w-2/3 justify-end">
            <button
              className=" border w-40 py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center bg-primary border-primary text-white"
              onClick={() => setOpenUploadCsv(true)}
            >
              Upload CSV
            </button>

            {/* <button
              className="w-40 border py-2 rounded-3xl text-xs text-white bg-primary border-primary  focus:outline-none flex justify-center items-center"
              onClick={() => openDetails(null, 'ADD')}
            >
              Add New Student
            </button> */}

            <Search
              placeholder={'Search'}
              width={'w-40'}
              onChange={(x) => console.log(x)}
              onEnter={() => console.log('enter')}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4 px-6">
        <div className="w-full">
          <DropDown
            width={'w-1/3'}
            title={'Select Institution'}
            data={institutes}
            field={'name'}
            type={'obj'}
            handleItemClick={(x) => {
              selectInstitution(x?._id);
            }}
            margin={'mt-4 mx-auto'}
          />
        </div>
        {isLoading ? (
          <img src="assets/img/loader.svg" className="w-14 m-auto mt-10" alt="" />
        ) : data ? (
          <div className="w-full py-4">
            <div className="bg-white m-auto border rounded-2xl border-borderYellow">
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-1/5 border-r-2 py-2 px-2">Student Name</div>
                  <div className="w-1/5 border-r-2 py-2 px-2">Father’s Name</div>
                  <div className="w-1/5 border-r-2 py-2 px-2">Date of Admission</div>
                  <div className="w-1/5 border-r-2 py-2 px-2">Class</div>
                  <div className="w-1/5 py-2 px-2">Action</div>
                </div>
              </div>
              <div className="showScroll" style={{ maxHeight: '71.5vh' }}>
                {Array.isArray(data) &&
                  data.map((x, idx) => {
                    return (
                      <div className="w-full">
                        <div
                          className={`justify-center flex w-full relative text-left py-2 px-4 text-xs font-medium items-center ${
                            idx !== data.length - 1 && 'border-b border-borderYellow'
                          }`}
                        >
                          <div className="w-1/5 px-2 text-textDark ">{x?.user?.full_name}</div>
                          <div className="w-1/5 px-2 text-textDark">
                            {(x?.user?.parentDetail &&
                              x?.user?.parentDetail.length > 0 &&
                              x?.user?.parentDetail[0].father_name) ||
                              ''}
                          </div>
                          <div className={`w-1/5 px-2 text-tableRowText`}>
                            {x?.user?.createdDate
                              ? moment(x?.user?.createdDate).format('ll')
                              : moment(x?.user?.createdAt).format('ll')}
                          </div>
                          <div className={`w-1/5 px-2 text-tableRowText`}>
                            {`${x.classInfo && x.classInfo.name} - ${x.sectionInfo && x.sectionInfo.name}`}
                          </div>{' '}
                          <div className={`w-1/5 px-2 flex`}>
                            {' '}
                            <button
                              className=" border  py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center px-2 mr-3 border-primary text-primary"
                              onClick={() => {
                                openDetails(x.user, 'VIEW');
                              }}
                            >
                              View & Edit
                            </button>
                            <button
                              className="  border px-4 py-2 rounded-3xl text-xs text-primary border-primary focus:outline-none"
                              onClick={() => {
                                handleDelete(x.user);
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        ) : (
          <p className="w-full text-center text-xl font-semibold my-10 text-primary">
            Select Institution To Get Student Data
          </p>
        )}
      </div>
    </div>
  );
}

export default NewStudents;

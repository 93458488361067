import React, { useEffect, useState } from 'react';
import useApi from '../api/useApi';
import { useStateValue } from '../ContextApi/StateProvider';
import DropDown from '../components/UI/DropDownCustom';
import { INSTITUTE_CLASS_SECTIONS, GET_ALL_STUDENTS, SAVE_PENDING_FEE, GET_PENDING_FEE } from '../api/urls';
import Search from '../components/UI/Search';
import ExcelExportBtn from '../components/UI/ExcelExportBtn';

const excelHeaders = [
  { label: 'RoledIn ID', key: 'id' },
  { label: 'Student', key: 'name' },
  { label: 'Father Name', key: 'father' },
  { label: 'Pendinfg Fee', key: 'fee' },
];

const PendingFee = () => {
  const [{ institutes }] = useStateValue();
  const [selectedInstitution, selectInstitution] = useState();
  const [classes, setClasses] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedClass, selectClass] = useState('');
  const [fee, setFee] = useState({});
  const [id, setStudentId] = useState();
  const [isAdd, setAdd] = useState(false);
  const [excelData, setExcelData] = useState([]);

  const [classesRes, getClasses] = useApi();
  const [studentsRes, getStudents] = useApi();
  const [pendingFeeRes, postPendingFee] = useApi();
  const [getPendingFeeRes, getPendingFee] = useApi();

  useEffect(() => {
    if (selectedInstitution) {
      getStudents(GET_ALL_STUDENTS(selectedInstitution), 'GET');
      getClasses(INSTITUTE_CLASS_SECTIONS(selectedInstitution), 'GET');
    }
  }, [selectedInstitution]);

  useEffect(() => {
    if (!classesRes.isLoading && !classesRes.isError && classesRes.data) {
      setClasses(classesRes.data);
    }
  }, [classesRes]);

  useEffect(() => {
    if (selectedClass) {
      const _class = classes.find((x) => `${x?.className} - ${x?.sectionName}` === selectedClass);
      getPendingFee(GET_PENDING_FEE(selectedInstitution, _class?.classId, _class?.sectionId), 'GET');
    }
  }, [selectedClass]);

  useEffect(() => {
    if (!pendingFeeRes.isLoading && !pendingFeeRes.isError && pendingFeeRes.status == 200) {
      setFee({ ...fee, [id]: -1 });
      alert('Success');
      if (selectedClass) {
        const _class = classes.find((x) => `${x?.className} - ${x?.sectionName}` === selectedClass);
        getPendingFee(GET_PENDING_FEE(selectedInstitution, _class?.classId, _class?.sectionId), 'GET');
      }
    }

    if (pendingFeeRes.isError) {
      alert('Failed');
    }
  }, [pendingFeeRes]);

  useEffect(() => {
    if (!getPendingFeeRes.isLoading && !getPendingFeeRes.isError && getPendingFeeRes.data) {
      if (
        getPendingFeeRes.data &&
        getPendingFeeRes?.data?.pendingStudentFee &&
        Array.isArray(getPendingFeeRes?.data?.pendingStudentFee)
      ) {
        const data = getPendingFeeRes?.data?.pendingStudentFee;
        let x = data.map((x) => {
          return {
            id: x?.studentId?.username,
            name: x?.studentId?.full_name,
            father:
              (x?.studentId?.parentDetail &&
                x?.studentId?.parentDetail.length > 0 &&
                x?.studentId?.parentDetail[0].father_name) ||
              '',
            fee: x?.prevBal,
          };
        });

        if (Array.isArray(x)) setExcelData(x);
      }
    }
  }, [getPendingFeeRes]);

  const handleSave = (studentId, institutionId) => {
    setStudentId(studentId);
    const body = {
      carryFrwd: +fee[studentId],
      month: 'April',
    };
    postPendingFee(SAVE_PENDING_FEE(institutionId, studentId), 'POST', body);
  };

  let data = studentsRes?.data && Array.isArray(studentsRes?.data) ? studentsRes?.data : [];
  if (search) {
    data = data.filter(
      (x) =>
        x.user?.full_name?.toLowerCase()?.includes(search.toLowerCase()) ||
        x.user?.username?.toLowerCase()?.includes(search.toLowerCase())
    );
  }

  if (selectedClass) {
    data = data.filter(
      (x) => `${x.classInfo && x.classInfo.name} - ${x.sectionInfo && x.sectionInfo.name}` === selectedClass
    );
  }

  return (
    <div className="w-4/5 pb-10">
      <div className="w-full bg-white rounded-xl h-auto mb-4">
        <div className="items-center px-6 py-3 border-b border-borderYellow">
          <div className="flex justify-between items-center">
            <div className="font-bold w-1/3">
              <p>Pending Fee</p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap w-full h-1/4 px-6">
          <div className={`"=w-full grid ${isAdd ? 'grid-cols-4' : 'grid-cols-5'} gap-x-4 mt-6`}>
            <DropDown
              width={'w-full'}
              title={'Select Institution'}
              data={institutes}
              field={'name'}
              type={'obj'}
              handleItemClick={(x) => {
                selectInstitution(x?._id);
              }}
              otherClasses={'text-textDark'}
            />

            <select
              className="w-full text-xs text-textDark rounded-3xl border border-tableRowText px-4 py-2 focus:outline-none"
              onChange={(e) => {
                selectClass(e.target.value);
              }}
            >
              <option value="">{isAdd ? 'All Classes' : 'select'}</option>
              {classes?.map((x) => (
                <option>{`${x?.className} - ${x?.sectionName}`}</option>
              ))}
            </select>

            <Search
              placeholder={'Search'}
              width={'w-full'}
              onChange={(x) => setSearch(x)}
              onEnter={(x) => setSearch(x)}
            />

            <button
              className="border px-4 py-2 rounded-3xl text-xs text-primary border-primary focus:outline-none"
              onClick={() => setAdd(!isAdd)}
            >
              {isAdd ? 'View' : 'Add'}
            </button>

            {!isAdd && (
              <ExcelExportBtn data={excelData} headers={excelHeaders} fileName={'Pending_fees.csv'} width={'w-36'} />
            )}
          </div>
          {studentsRes?.isLoading ? (
            <img src="assets/img/loader.svg" className="w-14 m-auto mt-10" alt="" />
          ) : isAdd ? (
            data ? (
              <div className="w-full py-4">
                <div className="bg-white m-auto border rounded-2xl border-borderYellow">
                  <div className="w-full flex bg-tableHeader rounded-3xl">
                    <div className="flex w-full relative text-left px-4 text-xs font-medium">
                      <div className="w-2/12 border-r-2 py-2 px-2">Student Name</div>
                      <div className="w-2/12 border-r-2 py-2 px-2">Father’s Name</div>
                      <div className="w-2/12 border-r-2 py-2 px-2">RoledIn ID</div>
                      <div className="w-2/12 border-r-2 py-2 px-2">Class</div>
                      <div className="w-4/12 py-2 px-2">Action</div>
                    </div>
                  </div>
                  <div className="showScroll" style={{ maxHeight: '71.5vh' }}>
                    {data.map((x, idx) => {
                      return (
                        <div key={x.user._id} className="w-full">
                          <div
                            className={`justify-center flex w-full relative text-left py-2 px-4 text-xs font-medium items-center ${
                              idx !== data.length - 1 && 'border-b border-borderYellow'
                            }`}
                          >
                            <div className="w-2/12 px-2 text-textDark ">{x?.user?.full_name}</div>
                            <div className="w-2/12 px-2 text-textDark">
                              {(x?.user?.parentDetail &&
                                x?.user?.parentDetail.length > 0 &&
                                x?.user?.parentDetail[0].father_name) ||
                                ''}
                            </div>
                            <div className={`w-2/12 px-2 text-tableRowText`}>{x?.user?.username}</div>
                            <div className={`w-2/12 px-2 text-tableRowText`}>
                              {`${x.classInfo && x.classInfo.name} - ${x.sectionInfo && x.sectionInfo.name}`}
                            </div>{' '}
                            <div className={`w-4/12 px-2 flex gap-x-4`}>
                              {' '}
                              <input
                                type="number"
                                placeholder="Enter Pending Fee"
                                className="w-full text-xs text-textDark rounded-3xl border border-tableRowText px-4 py-2 focus:outline-none"
                                value={fee[x.user._id] || ''}
                                onChange={(e) => setFee({ ...fee, [x.user._id]: e.target.value })}
                              />
                              <button
                                className="  border px-4 py-2 rounded-3xl text-xs text-primary border-primary focus:outline-none"
                                onClick={() => {
                                  handleSave(x.user._id, selectedInstitution);
                                }}
                                disabled={pendingFeeRes.isLoading}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <p className="w-full text-center text-xl font-semibold my-10 text-primary">
                Select Institution To Get Student Data
              </p>
            )
          ) : getPendingFeeRes?.data?.pendingStudentFee ? (
            <div className="w-full py-4">
              <div className="bg-white m-auto border rounded-2xl border-borderYellow">
                <div className="w-full flex bg-tableHeader rounded-3xl">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium">
                    <div className="w-1/4 border-r-2 py-2 px-2">Student Name</div>
                    <div className="w-1/4 border-r-2 py-2 px-2">Father’s Name</div>
                    <div className="w-1/4 border-r-2 py-2 px-2">RoledIn ID</div>
                    <div className="w-1/4 py-2 px-2">Pending Fee</div>
                  </div>
                </div>
                <div className="showScroll" style={{ maxHeight: '71.5vh' }}>
                  {getPendingFeeRes?.data?.pendingStudentFee.map((x, idx) => {
                    return (
                      <div key={x.studentId._id} className="w-full">
                        <div
                          className={`justify-center flex w-full relative text-left py-2 px-4 text-xs font-medium items-center ${
                            idx !== getPendingFeeRes?.data?.pendingStudentFee.length - 1 &&
                            'border-b border-borderYellow'
                          }`}
                        >
                          <div className="w-1/4 px-2 py-2 text-textDark ">{x?.studentId?.full_name}</div>
                          <div className="w-1/4 px-2 py-2 text-textDark">
                            {(x?.studentId?.parentDetail &&
                              x?.studentId?.parentDetail.length > 0 &&
                              x?.studentId?.parentDetail[0].father_name) ||
                              ''}
                          </div>
                          <div className={`w-1/4 px-2 py-2 text-tableRowText`}>{x?.studentId?.username}</div>
                          <div className={`w-1/4 px-2 py-2 text-tableRowText`}>{x?.prevBal}</div>{' '}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            <p className="w-full text-center text-xl font-semibold my-10 text-primary">
              Select Institution To Get Student Data
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PendingFee;

import React, { useState, useEffect } from 'react';
import Staff from '../components/StaffManagement/Staff';
import AddStaff from '../components/StaffManagement/AddStaff';
import DropDown from '../components/UI/DropDownCustom';
import useApi from '../api/useApi';
import { GET_ALL_TEACHING_STAFF, GET_ALL_NON_TEACHING_STAFF, GET_INSTITUTES, DELETE_USER } from '../api/urls';
import UploadCSV from '../components/StaffManagement/UploadCSV';

const StaffManagement = () => {
  const [institutes, setInstitutes] = useState([]);
  const [selectedInstitution, selectInstitution] = useState();
  const [createStaff, setCreateStaff] = useState(false);
  const [staffRole, setStaffRole] = useState('');
  const [openUploadCsv, setOpenUploadCsv] = useState(false);
  const [selectedStaff, selectStaff] = useState();
  const [teachingStaff, setTeachingStaff] = useState();
  const [nonTeachingStaff, setNonTeachingStaff] = useState();

  const [{ isLoading, isError, data }, getTeachingStaff] = useApi();
  const [nonTeachingState, getNonTeachingStaff] = useApi();
  const [instituteRes, getInstitutes] = useApi();
  const [deleteRes, deleteUser] = useApi();

  useEffect(() => {
    getInstitutes(GET_INSTITUTES, 'GET');
  }, []);

  useEffect(() => {
    if (!instituteRes.isLoading && !instituteRes.isError && instituteRes.data) {
      setInstitutes(instituteRes.data.response);
    }
  }, [instituteRes]);

  useEffect(() => {
    if (selectedInstitution) {
      getTeachingStaff(GET_ALL_TEACHING_STAFF(selectedInstitution), 'GET');
      getNonTeachingStaff(GET_ALL_NON_TEACHING_STAFF(selectedInstitution), 'GET');
    }
  }, [selectedInstitution]);

  useEffect(() => {
    if (selectedStaff) tooglePopUp();
  }, [selectedStaff]);

  const handleClose = () => {
    selectStaff(null);
    tooglePopUp();
  };

  useEffect(() => {
    if (!isLoading && !isError && data) {
      setTeachingStaff(data);
    }
  }, [isLoading, isError, data]);

  useEffect(() => {
    if (!nonTeachingState.isLoading && !nonTeachingState.isError && nonTeachingState.data) {
      setNonTeachingStaff(nonTeachingState.data);
    }
  }, [nonTeachingState]);

  const tooglePopUp = () => {
    setCreateStaff((prev) => !prev);
  };

  const refreshList = () => {
    getTeachingStaff(GET_ALL_TEACHING_STAFF(selectedInstitution), 'GET');
    getNonTeachingStaff(GET_ALL_NON_TEACHING_STAFF(selectedInstitution), 'GET');
  };

  const handleDelete = (x, role) => {
    deleteUser(DELETE_USER(x._id, role), 'DELETE');
  };

  useEffect(() => {
    if (!deleteRes.isLoading && !deleteRes.isError && deleteRes.status) {
      refreshList();
      alert('SUCCESS');
    }

    if (!deleteRes.isLoading && deleteRes.isError) {
      alert('FAILED');
    }
  }, [deleteRes]);

  return (
    <>
      {createStaff && (
        <AddStaff
          handleClose={handleClose}
          staffRole={staffRole}
          selectedStaff={selectedStaff}
          refreshList={refreshList}
          selectedInstitution={selectedInstitution}
        />
      )}

      {openUploadCsv && (
        <UploadCSV
          institutes={institutes}
          handleClose={() => setOpenUploadCsv(false)}
          staffRole={staffRole}
          refreshList={refreshList}
        />
      )}

      <div className={`w-4/5 ${createStaff && 'hidden'}`}>
        <div className="relative pb-10 ">
          <div className="py-4 bg-white rounded-lg">
            <DropDown
              width={'w-1/3'}
              title={'Select Institution'}
              data={institutes}
              field={'name'}
              type={'obj'}
              handleItemClick={(x) => {
                selectInstitution(x?._id);
              }}
              margin={'mx-auto'}
            />
          </div>

          {nonTeachingState.isLoading || isLoading ? (
            <img src="assets/img/loader.svg" className="w-14 m-auto mt-10" alt="" />
          ) : teachingStaff && nonTeachingStaff ? (
            <>
              <Staff
                setCreateStaff={setCreateStaff}
                selectStaff={selectStaff}
                data={teachingStaff}
                setStaffRole={setStaffRole}
                setOpenUploadCsv={setOpenUploadCsv}
                title={'Teaching Staff'}
                handleDelete={handleDelete}
              />

              <Staff
                setCreateStaff={setCreateStaff}
                selectStaff={selectStaff}
                data={nonTeachingStaff}
                setStaffRole={setStaffRole}
                setOpenUploadCsv={setOpenUploadCsv}
                title={'Non Teaching Staff'}
                handleDelete={handleDelete}
              />
            </>
          ) : (
            <p className="w-full text-center text-xl font-semibold my-10 text-primary">
              Select Institution To Get Student Data
            </p>
          )}
        </div>
      </div>
    </>
  );
};
export default StaffManagement;

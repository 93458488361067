import React, { useState, useEffect } from 'react';

import useApi from '../api/useApi';
import DropDown from '../components/UI/DropDownCustom';
import { CREATE_FEE_STRUCTURE } from '../api/urls';
import { useStateValue } from '../ContextApi/StateProvider';

function StudentMapping() {
  const formDataModel = {
    prevclassId: '',
    prevsectionId: '',
    classId: '',
    sectionId: '',
    session: '',
  };

  const [selectedInstitution, selectInstitution] = useState();
  const [selectedFeeStructure, selectFeeStructure] = useState();
  const [{ isLoading, isError, data, status }, createFeeStructure] = useApi();
  const [{ institutes, user }, dispatch] = useStateValue();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!selectedInstitution) {
      alert(`Please select Institution`);
      return;
    }

    if (!selectedFeeStructure) {
      alert(`Please select Fee Submission type`);
      return;
    }

    const body = {
      instituteId: selectedInstitution,
      feeStructure: selectedFeeStructure,
    };

    createFeeStructure(CREATE_FEE_STRUCTURE, 'POST', body);
  };

  useEffect(() => {
    if (!isLoading && !isError && status == 200) {
      handleClose();
      refreshList();
      alert('SUCCESS');
    }

    if (!isLoading && isError) {
      alert(data || 'Failed');
    }
  }, [isLoading, isError, data, status]);

  return (
    <>
      <div className="w-4/5 pb-10">
        <div className="w-full bg-white rounded-xl h-auto mb-4">
          <div className="items-center px-6 py-3 border-b border-borderYellow">
            <div className="flex justify-between items-center">
              <div className="font-bold">
                <p>Student Mapping From Old Session to New Session Class</p>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap w-full h-1/4 px-6">
            <div className="w-full py-4">
              <form onSubmit={handleSubmit}>
                <div className="px-8 border-b border-borderYellow">
                  <div className="my-4 w-full relative font-semibold ">
                    <label htmlFor="budget" className="text-textDark font-medium text-sm">
                      Select Institution<span className="text-red-500">*</span>
                    </label>
                    <DropDown
                      width={'w-1/3'}
                      title={'Select'}
                      data={institutes}
                      field={'name'}
                      type={'obj'}
                      handleItemClick={(x) => {
                        selectInstitution(x?._id);
                      }}
                      margin={'mt-2'}
                    />
                  </div>
                </div>

                <div className="flex flex-col w-full px-8 mt-4">
                  <p className=" font-medium ">Previous Session</p>

                  <div className="grid grid-cols-2 gap-x-6 my-2 w-full relative font-semibold ">
                    <div className="mt-2 gap-x-10 flex flex-col">
                      <label htmlFor="category" className="text-textDark font-medium text-sm">
                        Session<span className="text-red-500 ml-1">*</span>
                      </label>
                      <select
                        className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                        required
                        onChange={() => {}}
                      >
                        <option>dsa</option>
                      </select>
                    </div>

                    <div className="mt-2 gap-x-10 flex flex-col">
                      <label htmlFor="category" className="text-textDark font-medium text-sm">
                        Class<span className="text-red-500 ml-1">*</span>
                      </label>
                      <select
                        className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                        required
                        onChange={() => {}}
                      >
                        <option>dsa</option>
                      </select>
                    </div>

                    <div className="mt-2 gap-x-10 flex flex-col">
                      <label htmlFor="category" className="text-textDark font-medium text-sm">
                        Section<span className="text-red-500 ml-1">*</span>
                      </label>
                      <select
                        className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                        required
                        onChange={() => {}}
                      >
                        <option>dsa</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col w-full px-8 mt-4">
                  <p className=" font-medium ">New Session</p>

                  <div className="grid grid-cols-2 gap-x-6 my-2 w-full relative font-semibold ">
                    <div className="mt-2 gap-x-10 flex flex-col">
                      <label htmlFor="category" className="text-textDark font-medium text-sm">
                        Class<span className="text-red-500 ml-1">*</span>
                      </label>
                      <select
                        className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                        required
                        onChange={() => {}}
                      >
                        <option>dsa</option>
                      </select>
                    </div>

                    <div className="mt-2 gap-x-10 flex flex-col">
                      <label htmlFor="category" className="text-textDark font-medium text-sm">
                        Section<span className="text-red-500 ml-1">*</span>
                      </label>
                      <select
                        className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                        required
                        onChange={() => {}}
                      >
                        <option>dsa</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="flex gap-x-4 justify-end px-8 mb-4">
                  {createFeeStructure?.isLoading ? (
                    <img src="assets/img/loader.svg" className="w-14 ml-auto mt-2" alt="" />
                  ) : (
                    <button
                      className="w-40 border px-8 py-2 rounded-3xl text-xs text-white bg-primary focus:outline-none flex justify-center items-center"
                      type="submit"
                    >
                      Save
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentMapping;
